import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import RadioButtons from 'components/common/RadioButtons'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  changeSearchCondition,
  closeSearchModalDialog,
  handleClear,
  saveBulletinBoardSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import { BulletinBoardState, BulletinBoardType, ScreenUrl } from 'utils/enum'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import Loading from 'components/common/Loading'
import { fromCode } from 'utils/enum.utils'
import CommonChip from 'components/common/CommonChip'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  }
}))

const buttonsData = [
  { value: BulletinBoardState.all.code, label: BulletinBoardState.all.value },
  {
    value: BulletinBoardState.posting.code,
    label: BulletinBoardState.posting.value
  },
  {
    value: BulletinBoardState.reservation.code,
    label: BulletinBoardState.reservation.value
  },
  {
    value: BulletinBoardState.complete.code,
    label: BulletinBoardState.complete.value
  },
  {
    value: BulletinBoardState.draft.code,
    label: BulletinBoardState.draft.value
  }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pageId } = useParams()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }
  const [bulletinBoardList, setBulletinBoardList] = useState(initialState)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'bulletinBoard.state' in location.state
      ? location.state['bulletinBoard.state']
      : BulletinBoardState.all.code
  )
  const [searchConditions, setSearchConditions] = useState(
    location.state !== undefined
      ? location.state
      : props.bulletinBoardSearchParams
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})
  const [companyUserList, setCompanyUserList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [buildingList, setBuildingList] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchConditions, {
      'bulletinBoard.state': selectedStatus
    })
    if (!selectedStatus) {
      delete params['bulletinBoard.state']
    }
    props.saveBulletinBoardSearchCondition(params)
    setSearchConditions(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1),
      setOrderBy({})
    )
  }
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )

  async function fetchBulletinBoardList(bulletinBoardStateId, offset, limit) {
    const params = {}
    Object.keys(searchConditions).forEach(function(key) {
      if (searchConditions[key]) {
        switch (key) {
          case 'bulletinBoard.state': {
            if (searchConditions[key] != BulletinBoardState.all.code) {
              params.bulletin_board_state_id = { eq: searchConditions[key] }
              setSelectedStatus(searchConditions[key])
            }
            break
          }

          case 'bulletinBoard.is_important': {
            if (searchConditions[key] != 0) {
              params.is_important = { eq: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.title': {
            if (searchConditions[key] != 0) {
              params.title = {
                contains: searchConditions[key]
              }
            }
            break
          }

          case 'bulletinBoard.type': {
            if (searchConditions[key] != 0) {
              params.bulletin_board_type_id = { eq: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.display_start_at_str_date_from': {
            if (searchConditions[key] != 0) {
              params.display_start_at_from = { ge: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.display_start_at_str_date_to': {
            if (searchConditions[key] != 0) {
              params.display_start_at_to = { le: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.display_end_at_str_date_from': {
            if (searchConditions[key] != 0) {
              params.display_end_at_from = { ge: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.display_end_at_str_date_to': {
            if (searchConditions[key] != 0) {
              params.display_end_at_to = { le: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.building_id': {
            const building_id = []
            const buildings = JSON.parse(searchConditions[key])
            for (let building of buildings) {
              building_id.push(building.id)
            }
            if (building_id.length > 0) {
              params.building_id = { eq: building_id }
            }
            break
          }

          case 'bulletinBoard.updated_at_str_date_from': {
            if (searchConditions[key] != 0) {
              params.updated_at_from = { ge: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.updated_at_str_date_to': {
            if (searchConditions[key] != 0) {
              params.updated_at_to = { le: searchConditions[key] }
            }
            break
          }

          case 'bulletinBoard.updated_company_user': {
            if (searchConditions[key] != 0) {
              params.updated_by = { eq: searchConditions[key] }
            }
            break
          }
        }
      }
    })

    if (orderBy && Object.keys(orderBy).length > 0) {
      params.orderBy = [orderBy]
    }

    let result = await QueryHelper(
      'getBulletinBoardList',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('掲示板情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBulletinBoardList(result)
    }
  }

  useEffect(() => {
    if (Object.keys(searchConditions).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.bulletinBoardList}${pageNumber}`,
      state: searchConditions
    })
    props.saveBulletinBoardSearchCondition(searchConditions)
    fetchData(offset)
  }, [
    pageNumber,
    selectedStatus,
    setBulletinBoardList,
    searchConditions,
    orderBy
  ])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.bulletinBoardList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchConditions(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchBulletinBoardList(selectedStatus, offset, PAGE_LIMIT),
      companyUserList ? '' : fetchCompanyUserList(0, null),
      buildingList ? '' : fetchBuildingList()
    ])
    setIsLoading(false)
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            companyUserItems.push(companyUserItem)
          }
        }
      }
      setCompanyUserList(companyUserItems)
      setFetchedCompanyUserList(result.data)
    }
  }

  const fetchBuildingList = async () => {
    await API.graphql(graphqlOperation(queries.getBuildingNameList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getBuildingNameList
          setBuildingList(result.data)
        } else {
          props.enqueueSnackbar('物件一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('物件一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[0] === 'bulletinBoard.state') {
        if (entry[1] !== '') {
          setSelectedStatus(JSON.parse(entry[1]))
          params[entry[0]] = JSON.parse(entry[1])
        }
      } else if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }

    if (validateSearchCondition(params)) {
      return
    }

    props.changeSearchCondition(params)
    props.saveBulletinBoardSearchCondition(params)
    props.closeSearchModalDialog()
    setSearchConditions(params, setPageNumber(1), setOrderBy({}))
  }

  function validateSearchCondition(params) {
    const valid = {}

    if (
      params['bulletinBoard.display_start_at_str_date_from'] &&
      !params['bulletinBoard.display_start_at_str_date_to']
    ) {
      valid['bulletinBoard.display_start_at_str_date_to.message'] =
        '掲載期間開始日(From)が指定されてる時は掲載期間開始日(To)は必須です'
    }
    if (
      !params['bulletinBoard.display_start_at_str_date_from'] &&
      params['bulletinBoard.display_start_at_str_date_to']
    ) {
      valid['bulletinBoard.display_start_at_str_date_from.message'] =
        '掲載期間開始日(To)が指定されてる時は掲載期間開始日(From)は必須です'
    }

    if (
      params['bulletinBoard.display_end_at_str_date_from'] &&
      !params['bulletinBoard.display_end_at_str_date_to']
    ) {
      valid['bulletinBoard.display_end_at_str_date_to.message'] =
        '掲載期間終了日(From)が指定されてる時は掲載期間終了日(To)は必須です'
    }
    if (
      !params['bulletinBoard.display_end_at_str_date_from'] &&
      params['bulletinBoard.display_end_at_str_date_to']
    ) {
      valid['bulletinBoard.display_end_at_str_date_from.message'] =
        '掲載期間終了日(To)が指定されてる時は掲載期間終了日(From)は必須です'
    }

    if (
      params['bulletinBoard.updated_at_str_date_from'] &&
      !params['bulletinBoard.updated_at_str_date_to']
    ) {
      valid['bulletinBoard.updated_at_str_date_to.message'] =
        '更新日(From)が指定されてる時は更新日(To)は必須です'
    }
    if (
      !params['bulletinBoard.updated_at_str_date_from'] &&
      params['bulletinBoard.updated_at_str_date_to']
    ) {
      valid['bulletinBoard.updated_at_str_date_from.message'] =
        '更新日(To)が指定されてる時は更新日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const handleDeleteSearchConditions = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchConditions))
    switch (target) {
      case 'bulletinBoard.state': {
        setSelectedStatus(BulletinBoardState.all.code)
        delete params[target]
        break
      }

      case 'bulletinBoard.is_important': {
        delete params[target]
        break
      }

      case 'bulletinBoard.title': {
        delete params[target]
        break
      }

      case 'bulletinBoard.type': {
        delete params[target]
        break
      }

      case 'bulletinBoard.display_start_at_str_date_from':
      case 'bulletinBoard.display_start_at_str_date_to': {
        delete params['bulletinBoard.display_start_at_str_date_from']
        delete params['bulletinBoard.display_start_at_str_date_to']
        break
      }

      case 'bulletinBoard.display_end_at_str_date_from':
      case 'bulletinBoard.display_end_at_str_date_to': {
        delete params['bulletinBoard.display_end_at_str_date_from']
        delete params['bulletinBoard.display_end_at_str_date_to']
        break
      }

      case 'bulletinBoard.building_id': {
        const buildings = JSON.parse(searchConditions[target])
        const trimBuildings = buildings.filter(building => building.id !== id)
        if (trimBuildings.length > 0) {
          params[target] = JSON.stringify(trimBuildings)
        } else {
          delete params[target]
        }
        break
      }

      case 'bulletinBoard.updated_at_str_date_from':
      case 'bulletinBoard.updated_at_str_date_to': {
        delete params['bulletinBoard.updated_at_str_date_from']
        delete params['bulletinBoard.updated_at_str_date_to']
        break
      }

      case 'bulletinBoard.updated_company_user': {
        delete params[target]
        break
      }
      default: {
      }
    }
    params['pageNumber'] = 1
    props.saveBulletinBoardSearchCondition(params)
    setSearchConditions(params, setPageNumber(1), setOrderBy({}))
  }

  function isContainsBulletinBoardBySearchConditions() {
    const val = Object.keys(searchConditions).find(
      x =>
        x.match(/^bulletinBoard.+/) &&
        searchConditions[x] != BulletinBoardState.all.code
    )
    return val !== null && val !== undefined
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchConditions &&
          Object.keys(searchConditions).length > 0 &&
          isContainsBulletinBoardBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchConditions).map(key => {
                let label = ''
                switch (key) {
                  case 'bulletinBoard.state': {
                    if (
                      searchConditions[key] != BulletinBoardState.all.code &&
                      searchConditions[key] != undefined
                    ) {
                      label =
                        '状態: ' +
                        fromCode(
                          BulletinBoardState,
                          Number(searchConditions[key])
                        ).value
                    }
                    break
                  }

                  case 'bulletinBoard.is_important': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != '' &&
                      searchConditions[key]
                    ) {
                      label = '重要: ON'
                    }
                    break
                  }

                  case 'bulletinBoard.title': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = 'タイトル: ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.type': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label =
                        '種別: ' +
                        fromCode(
                          BulletinBoardType,
                          Number(searchConditions[key])
                        ).value
                    }
                    break
                  }

                  case 'bulletinBoard.display_start_at_str_date_from': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '掲載期間開始日(From): ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.display_start_at_str_date_to': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '掲載期間開始日(To): ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.display_end_at_str_date_from': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '掲載期間終了日(From): ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.display_end_at_str_date_to': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '掲載期間終了日(To): ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.building_id': {
                    if (searchConditions[key]) {
                      const buildings = JSON.parse(searchConditions[key])
                      return (
                        <>
                          {buildings &&
                            buildings.length > 0 &&
                            buildings.map(building => {
                              return (
                                <CommonChip
                                  label={building.TTY_NAME}
                                  onDelete={handleDeleteSearchConditions(
                                    key,
                                    building.id
                                  )}
                                />
                              )
                            })}
                        </>
                      )
                    }
                    break
                  }

                  case 'bulletinBoard.updated_at_str_date_from': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '更新日(From): ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.updated_at_str_date_to': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      label = '更新日(To): ' + searchConditions[key]
                    }
                    break
                  }

                  case 'bulletinBoard.updated_company_user': {
                    if (
                      searchConditions[key] != 0 &&
                      searchConditions[key] != ''
                    ) {
                      if (!fetchedCompanyUserList) {
                        break
                      }
                      for (let companyUser of fetchedCompanyUserList) {
                        if (
                          companyUser.company_user.id ===
                          Number(searchConditions[key])
                        ) {
                          const formattedName = getFormattedNameByStatus(
                            companyUser.company_user.name,
                            companyUser.company_user.status
                          )
                          label = '担当者: ' + formattedName
                          break
                        }
                      }
                    }
                    break
                  }
                  default: {
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchConditions(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchConditions['orderBy'] = sortParam
    setPageNumber(1)
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}>
          <Button
            component={Link}
            to="/bulletin-board/new"
            variant="contained"
            startIcon={<AddIcon />}
            className={classes.button}
            disabled={isRestrictedRole}
          >
            新規作成
          </Button>
        </Grid>
        <Grid item xs={5} className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={bulletinBoardList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && bulletinBoardList && bulletinBoardList.data ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              bulletinBoardList={bulletinBoardList.data}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={bulletinBoardList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            buildingList={buildingList}
            companyUserList={companyUserList}
            fetchedCompanyUserList={fetchedCompanyUserList}
            searchConditions={searchConditions}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.bulletinBoardList}
            searchConditions={searchConditions}
            stateName={'bulletinBoard.state'}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchConditions}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    bulletinBoardSearchParams: state.saveSearchCondition.bulletinBoard
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    },
    saveBulletinBoardSearchCondition: parameter => {
      dispatch(saveBulletinBoardSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
