import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import { makeStyles } from '@material-ui/core/styles'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import { Grid } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { connect } from 'react-redux'
import { setSelectDeliveryListId } from 'actions/Actions'
import { useHistory } from 'react-router-dom'
import { useRole } from 'hooks/useRole'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  tableSort: {
    verticalAlign: 'baseline',
    '& .MuiTableSortLabel-active': {
      color: '#889299'
    }
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  },
  settingButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.dark,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  },
  resultButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.dark
    }
  }
}))

const columns = [
  { id: 'id', label: 'ID', minWidth: 50, hasSort: true },
  {
    id: 'bulkDelivery_name',
    label: '配信リスト名',
    minWidth: 200,
    hasSort: false
  },
  { id: 'category', label: 'カテゴリ', minWidth: 150, hasSort: false },
  { id: 'send_counts', label: '送信件数', minWidth: 100, hasSort: false },
  { id: 'type', label: '形式', minWidth: 100, hasSort: false },
  { id: 'send_date', label: '送信日時', minWidth: 100, hasSort: true },
  { id: 'status', label: 'ステータス', minWidth: 100, hasSort: true },
  { id: 'updated_by', label: '更新者', minWidth: 100, hasSort: false },
  { id: '', label: '', minWidth: 80, hasSort: false }
]

function TransitionButton(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const { status, id, goToManagementMessageScreen } = props

  switch (status) {
    case 'SENT':
      return (
        <Button
          component={Link}
          onClick={() => goToManagementMessageScreen(id)}
          variant="contained"
          className={classes.resultButton}
        >
          結果
        </Button>
      )
    case 'SENDING':
      return null
    default:
      return (
        <Button
          component={Link}
          to={{
            pathname: `/message/bulk-delivery/confirm/${id}`,
            state: { referrer: '/message/bulk-delivery/list' }
          }}
          variant="contained"
          className={classes.settingButton}
          disabled={isRestrictedRole}
        >
          設定
        </Button>
      )
  }
}

function List(props) {
  const classes = useStyles()
  const history = useHistory()
  const { deliveryList } = props
  const [order, setOrder] = useState(
    props.orderBy && props.orderBy.sorted ? props.orderBy.sorted : ''
  )
  const [orderBy, setOrderBy] = useState(
    props.orderBy && props.orderBy.column ? props.orderBy.column : ''
  )

  const handleSort = columnId => {
    const sort = orderBy === columnId && order === 'asc' ? 'desc' : 'asc'
    setOrder(sort)
    setOrderBy(columnId)
    props.handleChangeSort({ column: columnId, sorted: sort })
  }

  const formatDate = date => {
    var tmp = date.substr(0, date.indexOf('T'))
    return tmp.replace(/-/g, '/')
  }

  const formatTime = date => {
    return date.substring(11, 16)
  }

  const displayStatus = status => {
    switch (status) {
      case 'CREATING': {
        return '作成中'
      }
      case 'SENT': {
        return '送信済'
      }
      case 'DRAFT': {
        return '下書き'
      }
      case 'RESERVE': {
        return '予約'
      }
      case 'SENDING': {
        return '送信中'
      }
      case 'ERROR': {
        return '失敗'
      }
      default: {
        return ''
      }
    }
  }

  const goToManagementMessageScreen = id => {
    props.setSelectDeliveryListId({
      selectDeliveryListId: id
    })
    history.push(`/message/list/1`)
  }

  return (
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>
          {columns.map(column => (
            <TableCell
              key={column.id}
              align={column.align}
              style={{
                minWidth: column.minWidth,
                width: column.width
              }}
              sortDirection={
                column.hasSort && orderBy === column.id ? order : false
              }
            >
              {column.hasSort ? (
                <TableSortLabel
                  active={orderBy === column.id}
                  direction={orderBy === column.id ? order : 'asc'}
                  className={classes.tableSort}
                  onClick={() => handleSort(column.id)}
                >
                  {column.label}
                  {orderBy === column.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  ) : null}
                </TableSortLabel>
              ) : (
                <>{column.label}</>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {deliveryList.length
          ? deliveryList.map((row, index) => {
              return (
                <TableRow key={index}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <Grid>{row.large_category.name}</Grid>
                    <Grid>{row.medium_category.name}</Grid>
                  </TableCell>
                  <TableCell>{row.send_counts}</TableCell>
                  <TableCell>
                    {row.type === 'SEARCH' ? '検索' : 'CSV'}
                  </TableCell>
                  <TableCell>
                    <Grid>{formatDate(row.send_date)}</Grid>
                    <Grid>{formatTime(row.send_date)}</Grid>
                  </TableCell>
                  <TableCell>{displayStatus(row.status)}</TableCell>
                  <TableCell>
                    {row.updated_user &&
                      getFormattedNameByStatus(
                        row.updated_user.display_name,
                        row.updated_user.status
                      )}
                  </TableCell>
                  <TableCell>
                    <TransitionButton
                      status={row.status}
                      id={row.id}
                      goToManagementMessageScreen={goToManagementMessageScreen}
                    />
                  </TableCell>
                </TableRow>
              )
            })
          : null}
      </TableBody>
    </Table>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectDeliveryListId: parameter => {
      dispatch(setSelectDeliveryListId(parameter))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(List)
