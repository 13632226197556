import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import Loading from 'components/common/Loading'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    margin: 'auto',
    width: 'auto'
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    height: 60
  },
  wrapList: {
    height: 600,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  list: {
    width: 300,
    height: 530,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  listWithSave: {
    width: 300,
    height: 480,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto'
  },
  buttonContent: {
    height: 50
  },
  buttonWrapper: {
    paddingTop: '8px',
    paddingBottom: '8px'
  },
  button: {
    margin: theme.spacing(0.5, 0)
  },
  primaryButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.lightest
    }
  },
  subButtonWrapper: {
    textAlign: 'end'
  }
}))

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

export default function TransferList(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const [checked, setChecked] = React.useState([])
  const [buildings, setBuildings] = React.useState([])
  const [orgBuildings, setOrgBuildings] = React.useState([])
  const [assignBuildings, setAssignBuildings] = React.useState([])
  const [orgAssignBuildings, setOrgAssignBuildings] = React.useState([])

  const leftChecked = intersection(checked, buildings)
  const rightChecked = intersection(checked, assignBuildings)

  useEffect(() => {
    if (props.assign) {
      setAssignBuildings(props.assign)
      setOrgAssignBuildings(props.assign)
    }
  }, [props.assign])

  useEffect(() => {
    if (props.buildings) {
      const assignedBuildingIds = assignBuildings.map(item => item.building.id)
      const filteredBuildings = props.buildings.filter(
        item => !assignedBuildingIds.includes(item.building.id)
      )
      setBuildings(filteredBuildings)
      setOrgBuildings(filteredBuildings)
    }
  }, [props.buildings, assignBuildings])

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setAssignBuildings(assignBuildings.concat(leftChecked))
    setBuildings(not(buildings, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    if (props.buildings) {
      const rightCheckedIds = rightChecked.map(item => item.building.id)
      const filteredBuildings = props.buildings.filter(item =>
        rightCheckedIds.includes(item.building.id)
      )

      setBuildings(buildings.concat(filteredBuildings))
    }
    setAssignBuildings(not(assignBuildings, rightChecked))
    setChecked(not(checked, rightChecked))
  }

  const handleCancel = event => {
    setAssignBuildings(orgAssignBuildings)
    setBuildings(orgBuildings)
  }

  const handleSave = event => {
    const buildings = []
    for (let assignBuilding of assignBuildings) {
      buildings.push(assignBuilding.building)
    }
    props.setAssignBuildings(buildings)
    props.handleCloseDialog()
  }

  const customList = (title, items, isLoading = false, isSave = false) => (
    <Card className={classes.wrapList}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0 || isRestrictedRole}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
      />
      <Divider />
      {isSave ? (
        <List
          className={classes.listWithSave}
          dense
          component="div"
          role="list"
        >
          {items &&
            items.map(value => {
              const labelId = `transfer-list-all-item-${value}-label`
              return (
                <ListItem
                  key={value.building.id}
                  role="listitem"
                  button
                  onClick={handleToggle(value)}
                  disabled={isRestrictedRole}
                >
                  <ListItemIcon>
                    <Checkbox
                      checked={checked.indexOf(value) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{ 'aria-labelledby': labelId }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={labelId}
                    primary={value.mst_tateya.TTY_NAME}
                  />
                </ListItem>
              )
            })}
          <ListItem />
        </List>
      ) : (
        <List className={classes.list} dense component="div" role="list">
          {isLoading ? (
            <Loading isLoading={isLoading} />
          ) : (
            <>
              {items &&
                items.map(value => {
                  const labelId = `transfer-list-all-item-${value}-label`
                  return (
                    <ListItem
                      key={value.building.id}
                      role="listitem"
                      button
                      onClick={handleToggle(value)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(value) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={value.mst_tateya.TTY_NAME}
                      />
                    </ListItem>
                  )
                })}
            </>
          )}
          <ListItem />
        </List>
      )}
      {isSave && (
        <>
          <div className={classes.buttonContent}>
            <Divider />
            <Grid container className={classes.buttonWrapper}>
              <Grid item xs={9} className={classes.subButtonWrapper}>
                <Button
                  variant="contained"
                  onClick={handleCancel}
                  disabled={isRestrictedRole}
                >
                  キャンセル
                </Button>
              </Grid>
              <Grid item xs={3} className={classes.subButtonWrapper}>
                <Button
                  className={classes.primaryButton}
                  variant="contained"
                  color="primary"
                  onClick={handleSave}
                  disabled={isRestrictedRole}
                >
                  設定
                </Button>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </Card>
  )

  return (
    <Grid
      container
      spacing={2}
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList('検索結果', buildings, props.isLoading)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('配信先', assignBuildings, null, true)}</Grid>
    </Grid>
  )
}
