import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import { Cache } from 'aws-amplify'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeSearchModalDialog,
  saveRoomCheckListSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import Loading from 'components/common/Loading'
import CommonChip from 'components/common/CommonChip'
import { RoomCheckListApplicationState, ScreenUrl } from 'utils/enum'
import RadioButtons from 'components/common/RadioButtons'
import { fromCode } from 'utils/enum.utils'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const buttonsData = [
  {
    value: RoomCheckListApplicationState.all.code,
    label: RoomCheckListApplicationState.all.value
  },
  {
    value: RoomCheckListApplicationState.open.code,
    label: RoomCheckListApplicationState.open.value
  },
  {
    value: RoomCheckListApplicationState.inProgress.code,
    label: RoomCheckListApplicationState.inProgress.value
  },
  {
    value: RoomCheckListApplicationState.complete.code,
    label: RoomCheckListApplicationState.complete.value
  }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'application_state_id' in location.state
      ? location.state.application_state_id
      : RoomCheckListApplicationState.all.code
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined
      ? location.state
      : props.roomCheckListSearchParams
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})

  const roomCheckListApplicationListCache = Cache.getItem(
    'totono-advertiser-roomCheckListApplication-list'
  )
  const { data, paging } = roomCheckListApplicationListCache
    ? roomCheckListApplicationListCache
    : initialState
  const [
    roomCheckListApplicationList,
    setRoomCheckListApplicationList
  ] = useState(data)
  const [companyGroupList, setCompanyGroupList] = useState(null)
  const [fetchedCompanyGroupList, setFetchedCompanyGroupList] = useState(null)
  const [companyUserList, setCompanyUserList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [pagingInfo, setPagingInfo] = useState(paging)
  const [hasDefect, setHasDefect] = useState(
    location.state !== undefined && 'hasDefect' in location.state
      ? location.state.hasDefect
      : false
  )
  const [isLoading, setIsLoading] = useState(false)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const handleSelectedButton = selectedStatus => {
    let params = {
      application_state_id: selectedStatus,
      hasDefect: hasDefect
    }
    if (!selectedStatus) {
      params['application_state_id'] = RoomCheckListApplicationState.all.code
    }
    params['pageNumber'] = 1
    props.saveRoomCheckListSearchCondition(params)
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  const handleDefectCheckButton = checked => {
    var params = Object.assign({}, searchCondition, { hasDefect: !hasDefect })
    params['pageNumber'] = 1
    props.saveRoomCheckListSearchCondition(params)
    setSearchCondition(params, setHasDefect(!hasDefect), setPageNumber(1))
  }

  async function getRoomCheckListApplicationList({ isDefect, offset, limit }) {
    var filter = { application_type_id: { eq: 3 } }
    var created_at = []
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'application_state_id') {
          filter['application_state_id'] = { eq: searchCondition[key] }
          setSelectedStatus(searchCondition[key])
          return
        }
        if (key == 'application.roomCheckList.created_at_from') {
          if ('application.roomCheckList.created_at_from' in searchCondition) {
            created_at.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('申込日(To)を指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'application.roomCheckList.created_at_to') {
          if ('application.roomCheckList.created_at_to' in searchCondition) {
            created_at.push(searchCondition[key])
          } else {
            props.enqueueSnackbar('申込日(From)を指定してください', {
              variant: 'error'
            })
            return
          }
        }
        if (key == 'application.roomCheckList.id') {
          filter['id'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'application.roomCheckList.customer_id') {
          filter['customer_id'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'application.roomCheckList.TTY_NAME') {
          filter['TTY_NAME'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'application.roomCheckList.is_arranged') {
          if (searchCondition[key] == 1) {
            filter.is_arranged = { eq: true }
          } else {
            filter.is_arranged = { ne: true }
          }
        }
        if (key == 'application.roomCheckList.company_group_id') {
          if (searchCondition[key] != 0) {
            filter.company_group_id = { eq: searchCondition[key] }
          }
        }
        if (key == 'application.roomCheckList.company_user_id') {
          if (searchCondition[key] != 0) {
            filter.company_user_id = { eq: searchCondition[key] }
          }
        }
      }
    })
    if (created_at.length > 0) {
      filter['created_at'] = {
        between: created_at
      }
    }
    if (isDefect) {
      filter['isDefect'] = { eq: isDefect }
    }
    let result = await QueryHelper(
      'getRoomCheckListApplicationList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('入居者室内点検表一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setRoomCheckListApplicationList(result.data, setPagingInfo(result.paging))
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.role_id != 5 && item.status === 1) {
            const companyGroupItem = {
              value: item.id,
              label: item.name
            }
            companyGroupItems.push(companyGroupItem)
          }
        }
      }
      setCompanyGroupList(companyGroupItems)
      setFetchedCompanyGroupList(result.data)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            companyUserItems.push(companyUserItem)
            if (!userMap.has(item.company_group.id)) {
              userMap.set(item.company_group.id, [companyUserItem])
            } else {
              const companyUserItemArray = userMap.get(item.company_group.id)
              companyUserItemArray.push(companyUserItem)
            }
          }
        }
      }
      setCompanyUserList(userMap)
      setFetchedCompanyUserList(result.data)
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {
      application_state_id: selectedStatus,
      hasDefect: hasDefect
    }
    for (let entry of formData.entries()) {
      if (entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    props.saveRoomCheckListSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  function validateSearchCondition(params) {
    const valid = {}

    if (
      params['application.roomCheckList.created_at_from'] &&
      !params['application.roomCheckList.created_at_to']
    ) {
      valid['application.roomCheckList.created_at_to.message'] =
        '申請日(From)が指定されてる時は申請日(To)は必須です'
    }
    if (
      !params['application.roomCheckList.created_at_from'] &&
      params['application.roomCheckList.created_at_to']
    ) {
      valid['application.roomCheckList.created_at_from.message'] =
        '申請日(To)が指定されてる時は申請日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      getRoomCheckListApplicationList({
        offset,
        isDefect: hasDefect,
        limit: PAGE_LIMIT
      }),
      companyGroupList ? '' : fetchCompanyGroupList(0, 0),
      companyUserList ? '' : fetchCompanyUserList(0, null)
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.applicationRoomCheckListList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, searchCondition, hasDefect])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.applicationRoomCheckListList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'hasDefect': {
        setHasDefect(!hasDefect)
        delete params[target]
        break
      }
      case 'application_state_id': {
        setSelectedStatus(RoomCheckListApplicationState.all.code)
        delete params[target]
        break
      }
      case 'application.roomCheckList.created_at_from': {
        delete params['application.roomCheckList.created_at_from']
        delete params['application.roomCheckList.created_at_to']
        break
      }
      case 'application.roomCheckList.created_at_to': {
        delete params['application.roomCheckList.created_at_from']
        delete params['application.roomCheckList.created_at_to']
        break
      }
      case 'application.roomCheckList.company_group_id': {
        delete params[target]
        delete params['application.roomCheckList.company_user_id']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.saveRoomCheckListSearchCondition(params)
    setSearchCondition(params, setPageNumber(1))
  }

  function isContainsApplicationRoomCheckListBySearchConditions() {
    const val = Object.keys(searchCondition).find(
      x =>
        x.match(/^application.roomCheckList.+/) ||
        (x === 'application_state_id' &&
          searchCondition[x] !== RoomCheckListApplicationState.all.code)
    )
    return val !== null && val !== undefined
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsApplicationRoomCheckListBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'application_state_id': {
                    if (
                      searchCondition[key] !==
                        RoomCheckListApplicationState.all.code &&
                      searchCondition[key] !== undefined
                    ) {
                      label =
                        '状態: ' +
                        fromCode(
                          RoomCheckListApplicationState,
                          Number(searchCondition[key])
                        ).value
                    }
                    break
                  }
                  case 'hasDefect': {
                    if (hasDefect) {
                      label = '不備あり: ON'
                    }
                    break
                  }
                  case 'application.roomCheckList.created_at_from': {
                    label = '申請日(From): ' + searchCondition[key]
                    break
                  }
                  case 'application.roomCheckList.created_at_to': {
                    label = '申請日(To): ' + searchCondition[key]
                    break
                  }
                  case 'application.roomCheckList.id': {
                    label = 'ID: ' + searchCondition[key]
                    break
                  }
                  case 'application.roomCheckList.customer_id': {
                    label = '入居者名: ' + searchCondition[key]
                    break
                  }
                  case 'application.roomCheckList.TTY_NAME': {
                    label = '物件名: ' + searchCondition[key]
                    break
                  }
                  case 'application.roomCheckList.is_arranged': {
                    const content =
                      searchCondition[key] == 1
                        ? '案件手配のみ表示'
                        : '案件手配を含めない'
                    label = content
                    break
                  }
                  case 'application.roomCheckList.company_group_id': {
                    if (fetchedCompanyGroupList) {
                      for (let companyGroup of fetchedCompanyGroupList) {
                        if (companyGroup.id === Number(searchCondition[key])) {
                          const formattedName = getFormattedNameByStatus(
                            companyGroup.name,
                            companyGroup.status
                          )
                          label = '担当グループ: ' + formattedName
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'application.roomCheckList.company_user_id': {
                    if (fetchedCompanyUserList) {
                      for (let companyUser of fetchedCompanyUserList) {
                        if (
                          companyUser.company_user.id ===
                          Number(searchCondition[key])
                        ) {
                          const formattedName = getFormattedNameByStatus(
                            companyUser.company_user.name,
                            companyUser.company_user.status
                          )
                          label = '担当者: ' + formattedName
                          break
                        }
                      }
                    }
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs={3} className={classes.tableTips}>
          <FormControlLabel
            control={
              <Checkbox
                checked={hasDefect}
                onChange={handleDefectCheckButton}
                value="primary"
              />
            }
            label="不備ありのみ表示"
          />
          <Grid item xs className={classes.tableTips} />
        </Grid>
        <Grid item xs className={classes.tableTips}>
          <RadioButtons
            current={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs={3} className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={pagingInfo.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && roomCheckListApplicationList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              roomCheckListApplicationList={roomCheckListApplicationList}
              searchCondition={searchCondition}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={pagingInfo.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        // title="物件の検索"
        // description="※削除すると、各所から表示されなくなります。"
        content={
          <SearchFormContent
            companyGroupList={companyGroupList}
            companyUserList={companyUserList}
            fetchedCompanyGroupList={fetchedCompanyGroupList}
            fetchedCompanyUserList={fetchedCompanyUserList}
            searchCondition={searchCondition}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.applicationRoomCheckListList}
            searchConditions={searchCondition}
            stateName={'application_state_id'}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    roomCheckListSearchParams: state.saveSearchCondition.roomCheckList
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveRoomCheckListSearchCondition: parameter => {
      dispatch(saveRoomCheckListSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
