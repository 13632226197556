import React, { useEffect, useState } from 'react'
import { Auth, Storage } from 'aws-amplify'
import uuid from 'uuid/v4'
import { useHistory, withRouter } from 'react-router-dom'
import { MutationHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { Paper, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import Link from '@material-ui/core/Link'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'

import FormLayout from 'components/common/FormLayout'
import Dropdown from 'components/common/Form/Dropdown'
import Loading from 'components/common/Loading'

import TitleImage from './TitleImage'
import Image from './Image'
import Movie from './Movie'
import Pdf from './Pdf'

import AssignForm from './AssignForm'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import { connect } from 'react-redux'
import { setLoadData } from 'actions/Actions'
import RichTextEditor from 'components/General/RichTextEditor'
import { FormProvider, useForm } from 'react-hook-form'
import { ErrorMessages } from 'utils/errorMessages'
import { useRole } from 'hooks/useRole'
import { useOwnerFunctionEnabled } from 'hooks/useOwnerFunctionEnabled'
import { AttachmentType } from 'utils/enum'
import { Regexes } from 'utils/regexes'
import { Note } from 'utils/note'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  top: {
    flex: 1
  },
  formRow: {
    flex: 1,
    '&:nth-child(n+2)': {
      marginTop: 20
    }
  },
  body: {
    flex: 1,
    marginTop: 20
  },
  bottom: {
    flex: 1,
    width: '100%',
    padding: '10px 0px 10px 0px',
    marginTop: 20,
    backgroundColor: theme.palette.background.paper
  },
  divider: {
    width: '100%',
    height: '24px'
  },
  important: {
    marginTop: '10px'
  },
  switchButton: {
    '& .Mui-checked': {
      color: theme.palette.c_blue.main
    },
    '& .Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.c_blue.main
    }
  },
  input: {
    display: 'none'
  },
  itemContainer: {
    marginTop: 10
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.lightest
    }
  },
  subButton: {
    color: theme.palette.c_black.main,
    backgroundColor: theme.palette.c_white.main,
    '&:hover': {
      backgroundColor: theme.palette.c_white.lightest
    }
  },
  iconButton: {
    fontSize: '1.0rem',
    padding: '0px 12px 0px 12px',
    marginTop: '6px'
  },
  dialog: {
    width: 'auto'
  },
  linkText: {
    marginTop: '7px',
    marginRight: '5px',
    color: theme.palette.c_blue.main,
    float: 'left'
  },
  helperText: {
    marginLeft: '14px'
  },
  section: {
    padding: '15px 20px'
  }
}))

function BulletinBoardForm(props) {
  const classes = useStyles()
  const history = useHistory()
  const methods = useForm()
  const { isOwnerFunctionEnabled } = useOwnerFunctionEnabled()
  const { role3, role12 } = useRole()
  const isRestrictedRole = role3()
  const [id, setId] = useState(null)
  const [isImportant, setIsImportant] = useState(false)
  const [orgIsImportant, setOrgIsImportant] = useState(false)
  const [bulletinBoardTypeId, setBulletinBoardTypeId] = useState(null)
  const [orgBulletinBoardTypeId, setOrgBulletinBoardTypeId] = useState(null)
  const [
    validateBulletinBoardTypeId,
    setValidateBulletinBoardTypeId
  ] = useState({})
  const dropdownBulletinBoardTypeList = createDropdownBulletinBoardTypeList(
    props.bulletinBoardTypeList
  )

  const [titleImage, setTitleImage] = useState(
    props.titleAttachment ? props.titleAttachment : null
  )
  const [orgTitleImage, setOrgTitleImage] = useState()

  const [deletedTitleImage, setDeletedTitleImage] = useState()

  const [title, setTitle] = useState(
    props.bulletinBoard ? props.bulletinBoard.title : ''
  )
  const [orgTitle, setOrgTitle] = useState(
    props.bulletinBoard ? props.bulletinBoard.title : ''
  )
  const [validateTitle, setValidateTitle] = useState({})
  const [body, setBody] = useState(
    props.bulletinBoard ? props.bulletinBoard.body : ''
  )
  const [orgBody, setOrgBody] = useState(
    props.bulletinBoard ? props.bulletinBoard.body : ''
  )
  const [checkError, setCheckError] = useState({
    body: ''
  })
  const [url, setUrl] = useState(
    props.bulletinBoard ? props.bulletinBoard.url : ''
  )
  const [orgUrl, setOrgUrl] = useState(
    props.bulletinBoard ? props.bulletinBoard.url : ''
  )

  const [urlText, setUrlText] = useState(
    props.bulletinBoard ? props.bulletinBoard.url_text : ''
  )
  const [orgUrlText, setOrgUrlText] = useState(
    props.bulletinBoard ? props.bulletinBoard.url_text : ''
  )
  const [validateUrlText, setValidateUrlText] = useState({})

  const [images, setImages] = useState([])
  const [orgImages, setOrgImages] = useState([])
  const [deletedImages, setDeletedImages] = useState([])
  const [movie, setMovie] = useState(null)
  const [orgMovie, setOrgMovie] = useState(null)
  const [deletedMovie, setDeletedMovie] = useState(null)
  const [pdfs, setPdfs] = useState([])
  const [orgPdfs, setOrgPdfs] = useState(null)
  const [deletedPdfs, setDeletedPdfs] = useState([])

  const [startAt, setStartAt] = useState(null)
  const [orgStartAt, setOrgStartAt] = useState(null)
  const [validateStartAt, setValidateStartAt] = useState({})
  const [endAt, setEndAt] = useState(null)
  const [orgEndAt, setOrgEndAt] = useState(null)
  const [validateEndAt, setValidateEndAt] = useState({})

  const [assignFlg, setAssignFlg] = useState('0')
  const [orgAssignFlg, setOrgAssignFlg] = useState('1')
  const [isOpen, setIsOpen] = useState(false)
  const [assignBuildings, setAssignBuildings] = useState([])
  const [orgAssignBuildings, setOrgAssignBuildings] = useState([])

  const [withOwner, setWithOwner] = useState(false)
  const [orgWithOwner, setOrgWithOwner] = useState(false)

  useEffect(() => {
    if (props.bulletinBoard) {
      const bulletinBoard = props.bulletinBoard
      setId(bulletinBoard.id)
      setIsImportant(bulletinBoard.is_important)
      setBulletinBoardTypeId(bulletinBoard.bulletin_board_type_id)
      setTitle(bulletinBoard.title)
      setBody(bulletinBoard.body)
      setUrl(bulletinBoard.url)
      setUrlText(bulletinBoard.url_text)
      setStartAt(bulletinBoard.display_start_at)
      setEndAt(bulletinBoard.display_end_at)
      setWithOwner(bulletinBoard.with_owner)
      setOrgIsImportant(bulletinBoard.is_important)
      setOrgBulletinBoardTypeId(bulletinBoard.bulletin_board_type_id)
      setOrgTitle(bulletinBoard.title)
      setOrgBody(bulletinBoard.body)
      setOrgUrl(bulletinBoard.url)
      setOrgUrlText(bulletinBoard.url_text)
      setOrgStartAt(bulletinBoard.display_start_at)
      setOrgEndAt(bulletinBoard.display_end_at)
      if (bulletinBoard.is_assign) {
        setAssignFlg('1')
        setOrgAssignFlg('1')
        if (props.buildings && props.buildings.length > 0) {
          setAssignBuildings(props.buildings)
          setOrgAssignBuildings(props.buildings)
        } else {
          setAssignBuildings([])
          setOrgAssignBuildings([])
        }
      } else {
        setAssignFlg('0')
        setOrgAssignFlg('0')
      }
      setOrgWithOwner(bulletinBoard.with_owner)
    }
    if (props.attachments && props.attachments.length > 0) {
      const attachments = props.attachments
      const images = []
      let movie = null
      const pdfs = []
      for (let attachment of attachments) {
        if (attachment.attachment_type_id === 1) {
          images.push(attachment)
        }
        if (attachment.attachment_type_id === 2) {
          movie = attachment
        }
        if (attachment.attachment_type_id === 3) {
          pdfs.push(attachment)
        }
      }
      setImages(images)
      setOrgImages(images)
      setMovie(movie)
      setOrgMovie(movie)
      setPdfs(pdfs)
      setOrgPdfs(pdfs)
    } else {
      setImages([])
      setOrgImages([])
      setMovie(null)
      setOrgMovie(null)
      setPdfs([])
      setOrgPdfs(null)
    }
    if (props.titleAttachment) {
      setTitleImage(props.titleAttachment)
      setOrgTitleImage(props.titleAttachment)
    } else {
      setTitleImage(null)
      setOrgTitleImage(null)
    }
  }, [
    props.attachments,
    props.bulletinBoard,
    props.buildings,
    props.titleAttachment
  ])

  function createDropdownBulletinBoardTypeList(bulletinBoardTypeList) {
    const dropdownBulletinBoardTypeList = []
    if (bulletinBoardTypeList && bulletinBoardTypeList.length > 0) {
      for (let bulletinBoardType of bulletinBoardTypeList) {
        dropdownBulletinBoardTypeList.push({
          label: bulletinBoardType.name,
          value: bulletinBoardType.id
        })
      }
    }
    return dropdownBulletinBoardTypeList
  }

  const handleChangeBulletinBoardType = event => {
    setBulletinBoardTypeId(event.target.value)
  }

  const handleChangeIsImportant = event => {
    setIsImportant(event.target.checked)
  }

  const handleChangeTitle = event => {
    setTitle(event.target.value)
  }

  const handleRichTextChange = (id, content) => {
    setBody(content)
  }

  const handleChangeUrl = event => {
    setUrl(event.target.value)
  }

  const handleChangeUrlText = event => {
    setUrlText(event.target.value)
  }

  const handleDeleteImage = index => event => {
    const splicedImages = [...images.filter((item, i) => i !== index)]
    document.getElementById('contained-button-image').value = null
    if (images && images.length > 0 && images[index].id) {
      const temp = [...deletedImages]
      temp.push(images[index])
      setDeletedImages([...temp])
    }
    setImages([...splicedImages])
  }

  const handleDeleteTitleImage = () => {
    if (titleImage && titleImage.id) {
      setDeletedTitleImage({ ...titleImage })
    }
    setTitleImage(null)
  }

  const handleChangeImage = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setImages([...images, file])
    }
    reader.readAsDataURL(file)
    // 同じ画像を選択する場合を考慮し、リセットする
    event.target.value = ''
  }

  const handleChangeTitleImage = event => {
    const file = event.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = AttachmentType.image
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setTitleImage(file)
    }
    reader.readAsDataURL(file)
  }

  const handleDeleteMovie = event => {
    document.getElementById('contained-button-movie').value = null
    if (movie && movie.id) {
      setDeletedMovie(movie)
    }
    setMovie(null)
  }

  const handleChangeMovie = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setMovie(file)
    }
    reader.readAsDataURL(file)
  }

  const handleDeletePdf = index => event => {
    const splicedPdf = [...pdfs.filter((item, i) => i !== index)]
    document.getElementById('contained-button-pdf').value = null
    if (pdfs && pdfs.length > 0 && pdfs[index].id) {
      const temp = [...deletedPdfs]
      temp.push(pdfs[index])
      setDeletedPdfs([...temp])
    }
    setPdfs([...splicedPdf])
  }

  const handleChangePdf = e => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onload = e => {
      file.attachment_type_id = 1
      file.mime_type = file.type
      file.filename = file.name
      file.body = reader.result
      setPdfs([...pdfs, file])
    }
    reader.readAsDataURL(file)
  }

  const handleChangeStartAt = date => {
    if (!date) {
      setStartAt(null)
    } else {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      setStartAt(date)
    }
  }

  const handleChangeEndAt = date => {
    if (!date) {
      setEndAt(null)
    } else {
      date.setHours(0)
      date.setMinutes(0)
      date.setSeconds(0)
      date.setMilliseconds(0)
      setEndAt(date)
    }
  }

  const handleChangeAssign = event => {
    setAssignFlg(event.target.value)
  }

  const handleOpenDialog = event => {
    setIsOpen(true)
  }

  const handleCloseDialog = event => {
    setIsOpen(false)
  }

  const handleChangeWithOwner = event => {
    setWithOwner(event.target.checked)
  }

  const handleCancel = event => {
    setIsImportant(orgIsImportant)
    setBulletinBoardTypeId(orgBulletinBoardTypeId)
    setTitleImage(orgTitleImage)
    setTitle(orgTitle)
    setBody(orgBody)
    setUrl(orgUrl)
    setUrlText(orgUrlText)
    setStartAt(orgStartAt)
    setEndAt(orgEndAt)
    setAssignFlg(orgAssignFlg)
    setAssignBuildings(orgAssignBuildings)
    setMovie(orgMovie)
    setPdfs(orgPdfs)
    setImages(orgImages)
    setWithOwner(orgWithOwner)
  }

  const handleSubmit = async event => {
    event.preventDefault()
    props.setLoadData(true)
    if (validateForm()) {
      props.setLoadData(false)
      props.enqueueSnackbar('入力内容に誤りがあるため保存できません', {
        variant: 'warning'
      })
      return
    }

    const params = {}

    params.is_important = isImportant

    if (bulletinBoardTypeId) {
      params.bulletin_board_type_id = bulletinBoardTypeId
    }

    if (title) {
      params.title = title
    }
    if (body) {
      // 画面を切り替えない かつ 同じ内容で登録するときに不要にspanタグが追加されるのを防ぐため
      const startsWithSpanTag = body.startsWith(
        '<span style="font-size: 15px"><p>'
      )
      if (!startsWithSpanTag) {
        // defaultValueで指定したテキストサイズにはfont-sizeが記載されないため、一番外を囲んで指定がないものを全て15pxにしてデータを登録する
        params.body = `<span style="font-size: 15px">${body}</span>`
      }
    }
    if (url) {
      params.url = url
    } else if (orgUrl) {
      params.url = ''
    }
    if (urlText) {
      params.url_text = urlText
    }
    if (startAt) {
      params.display_start_at = startAt
    }
    if (endAt) {
      params.display_end_at = endAt
    }
    params.with_owner = withOwner
    params.is_assign = assignFlg === '1'
    if (assignFlg === '1' && assignBuildings && assignBuildings.length > 0) {
      params.buildings = assignBuildings
    }

    if (titleImage) {
      const creds = await Auth.currentCredentials()
      let titleAttachment

      if (!titleImage.id) {
        const result = await fileupload(creds, 'images', titleImage)
        titleAttachment = {
          attachment_type_id: AttachmentType.image,
          mime_type: titleImage.type,
          filename: titleImage.name,
          key: result.key
        }
      }

      if (titleAttachment) {
        params.title_attachment = titleAttachment
      }
    } else if (deletedTitleImage && deletedTitleImage.id) {
      params.title_attachment = {
        id: deletedTitleImage.id,
        key: deletedTitleImage.key,
        status: 3
      }
    }

    if ((images && images.length > 0) || movie || (pdfs && pdfs.length > 0)) {
      const creds = await Auth.currentCredentials()
      params.attachments = {}
      if (images && images.length > 0) {
        const attachmentImages = []
        for (let image of images) {
          if (!image.id) {
            const result = await fileupload(creds, 'images', image)
            attachmentImages.push({
              attachment_type_id: 1,
              mime_type: image.type,
              filename: image.name,
              key: result.key
            })
          }
        }
        if (attachmentImages.length > 0) {
          params.attachments.images = attachmentImages
        }
      }
      if (movie && !movie.id) {
        params.attachments.videos = []
        const result = await fileupload(creds, 'videos', movie)
        params.attachments.videos.push({
          attachment_type_id: 2,
          mime_type: movie.type,
          filename: movie.name,
          key: result.key
        })
      }
      if (pdfs && pdfs.length > 0) {
        const attachmentFiles = []
        for (let pdfFile of pdfs) {
          if (!pdfFile.id) {
            const result = await fileupload(creds, 'files', pdfFile)
            attachmentFiles.push({
              attachment_type_id: 3,
              mime_type: pdfFile.type,
              filename: pdfFile.name,
              key: result.key
            })
          }
        }
        if (attachmentFiles.length > 0) {
          params.attachments.files = attachmentFiles
        }
      }
    }

    if (
      (deletedImages && deletedImages.length > 0) ||
      deletedMovie ||
      (deletedPdfs && deletedPdfs.length > 0)
    ) {
      if (!params.attachments) {
        params.attachments = {}
      }
      if (deletedImages && deletedImages.length > 0) {
        if (!params.attachments.images) {
          params.attachments.images = []
        }
        for (let image of deletedImages) {
          if (image.id) {
            params.attachments.images.push({
              id: image.id,
              key: image.key,
              status: 3
            })
          }
        }
      }

      if (deletedMovie) {
        if (!params.attachments.videos) {
          params.attachments.videos = []
        }
        params.attachments.videos.push({
          id: deletedMovie.id,
          key: deletedMovie.key,
          status: 3
        })
      }

      if (deletedPdfs && deletedPdfs.length > 0) {
        if (!params.attachments.files) {
          params.attachments.files = []
        }
        for (let file of deletedPdfs) {
          if (file.id) {
            params.attachments.files.push({
              id: file.id,
              key: file.key,
              status: 3
            })
          }
        }
      }
    }

    let result
    if (id) {
      params.id = id
      result = await MutationHelper('updateBulletinBoard', { input: params })
      if (result.error) {
        props.enqueueSnackbar('保存できませんでした', {
          variant: 'error'
        })
      } else {
        await props.fetchBulletinBoard(result.id)
        props.enqueueSnackbar('保存しました', {
          variant: 'success'
        })
      }
    } else {
      if (props.bulletinBoardStatus) {
        params.status = props.bulletinBoardStatus
      } else {
        params.status = 2
      }
      result = await MutationHelper('createBulletinBoard', { input: params })
      if (result.error) {
        props.enqueueSnackbar('保存できませんでした', {
          variant: 'error'
        })
      } else {
        props.enqueueSnackbar('保存しました', {
          variant: 'success'
        })
        history.push(`/bulletin-board/edit/${result.id}`)
      }
    }
    props.setLoadData(false)
  }

  const fileupload = async (creds, folder, file) => {
    const extension = file.name.split('.')[1]
    const { type: mimeType } = file
    const key = `${folder}/${uuid()}.${extension}`
    const result = await Storage.put(key, file, {
      level: 'protected',
      identityId: creds.params.User.CompanyId,
      contentType: mimeType
    })
    return result
  }

  const handleDeleteBulletinBoard = async event => {
    event.preventDefault()
    if (!window.confirm(`削除します。よろしいですか？`)) {
      return
    }
    const params = {}
    params.id = id
    params.status = 3
    const result = await MutationHelper('updateBulletinBoard', {
      input: params
    })
    if (result.error) {
      props.enqueueSnackbar('削除できませんでした', {
        variant: 'error'
      })
    } else {
      props.enqueueSnackbar('削除しました', {
        variant: 'success'
      })
      history.push(`/bulletin-board/list/1`)
    }
  }

  function validateForm() {
    let isError = false
    isError = Object.values(checkError).some(error => error !== '')

    if (!bulletinBoardTypeId) {
      setValidateBulletinBoardTypeId({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateBulletinBoardTypeId({})
    }

    if (!title || title === '') {
      setValidateTitle({ hasError: true, message: '必須です' })
      isError = true
    } else if (title.length > 100) {
      setValidateTitle({ hasError: true, message: '100文字を超えています' })
      isError = true
    } else {
      setValidateTitle({})
    }

    const bodyParts = body
      .split(Regexes.NewlineAndSpaces)
      .filter(part => part.trim() !== '')
    if (!body || bodyParts.length === 0) {
      setCheckError(prevCheckError => ({
        ...prevCheckError,
        body: ErrorMessages.Required
      }))
      isError = true
    }

    if (urlText && urlText.length > 100) {
      setValidateUrlText({ hasError: true, message: '100文字を超えています' })
      isError = true
    } else {
      setValidateUrlText({})
    }

    if (!startAt) {
      setValidateStartAt({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateStartAt({})
    }

    if (!endAt) {
      setValidateEndAt({ hasError: true, message: '必須です' })
      isError = true
    } else {
      setValidateEndAt({})
    }

    if (startAt && endAt) {
      const startDate = new Date(startAt)
      const endDate = new Date(endAt)
      if (startDate > endDate) {
        setValidateStartAt({
          hasError: true,
          message: '掲載終了日より後の日にちは指定できません'
        })
        setValidateEndAt({
          hasError: true,
          message: '掲載開始日より前の日にちは指定できません'
        })
        isError = true
      } else {
        setValidateStartAt({})
        setValidateEndAt({})
      }
    }
    return isError
  }

  return (
    <>
      {props.isLoading ? (
        <Loading isLoading={props.isLoading} />
      ) : (
        <FormProvider {...methods}>
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <div className={classes.root}>
              <div className={classes.top}>
                <Paper>
                  <FormLayout title="種別">
                    <Grid container spacing={2}>
                      <Grid alignItems="center" item xs={6}>
                        <FormControlLabel
                          className={classes.important}
                          control={
                            <Switch
                              className={classes.switchButton}
                              checked={isImportant}
                              onChange={handleChangeIsImportant}
                              name="isPrimary"
                              disabled={isRestrictedRole}
                            />
                          }
                          label="重要"
                          labelPlacement="start"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Dropdown
                          required
                          name="BulletinBoardType"
                          label={'種別'}
                          onChange={handleChangeBulletinBoardType}
                          defaultValue={bulletinBoardTypeId}
                          items={dropdownBulletinBoardTypeList}
                          hasError={validateBulletinBoardTypeId.hasError}
                          errorMessage={validateBulletinBoardTypeId.message}
                          disabled={isRestrictedRole}
                        />
                      </Grid>
                    </Grid>
                  </FormLayout>
                </Paper>
              </div>

              <div className={classes.body}>
                <Paper>
                  <FormLayout title="タイトル画像">
                    <Grid container spacing={1}>
                      <Grid alignItems="center" item xs={12}>
                        <div className={classes.body}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-title-image"
                            type="file"
                            onChange={handleChangeTitleImage}
                            disabled={isRestrictedRole}
                          />
                        </div>
                        {titleImage && (
                          <div className={classes.body}>
                            <TitleImage
                              attachment={titleImage}
                              handleDelete={handleDeleteTitleImage}
                              style={{ marginBottom: 10 }}
                              disabledIcon={isRestrictedRole}
                            />
                          </div>
                        )}
                        {titleImage == null && (
                          <div className={classes.body}>
                            <label
                              htmlFor="contained-button-title-image"
                              className={classes.labelButton}
                            >
                              <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                color="primary"
                                component="span"
                                startIcon={<AddIcon />}
                                disabled={isRestrictedRole}
                              >
                                画像を追加
                              </Button>
                              <FormHelperText>
                                ※画像推奨サイズは横1080px × 縦830px以上です
                              </FormHelperText>
                            </label>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </FormLayout>
                </Paper>
              </div>

              <div className={classes.body}>
                <Paper>
                  <FormLayout title="お知らせ内容" subTitle={Note.RichTextLink}>
                    <Grid container spacing={1}>
                      <Grid alignItems="center" item xs={12}>
                        <TextField
                          required
                          id="title"
                          label={'タイトル'}
                          fullWidth
                          autoComplete="title"
                          variant="filled"
                          value={title}
                          defaultValue={title}
                          onChange={handleChangeTitle}
                          error={validateTitle.hasError}
                          helperText={
                            validateTitle.message
                              ? validateTitle.message
                              : '100文字以内'
                          }
                          disabled={isRestrictedRole}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <RichTextEditor
                          id="body"
                          maxLength={2000}
                          placeholder="本文を入力"
                          defaultValue={body}
                          setCheckError={setCheckError}
                          checkError={checkError}
                          readOnly={isRestrictedRole}
                          handleChange={handleRichTextChange}
                          required
                        />
                      </Grid>
                      <Grid alignItems="center" item xs={12}>
                        <TextField
                          label={'関連URL'}
                          id="url"
                          fullWidth
                          autoComplete="url"
                          variant="filled"
                          value={url}
                          defaultValue={url}
                          onChange={handleChangeUrl}
                          disabled={isRestrictedRole}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="URLに表示するテキスト"
                          id="url_text"
                          fullWidth
                          variant="filled"
                          value={urlText}
                          defaultValue={urlText}
                          onChange={handleChangeUrlText}
                          error={validateUrlText.hasError}
                          helperText={
                            validateUrlText.message
                              ? validateUrlText.message
                              : '100文字以内'
                          }
                          disabled={isRestrictedRole}
                        />
                      </Grid>
                    </Grid>
                  </FormLayout>
                </Paper>
              </div>

              <div className={classes.body}>
                <Paper>
                  <FormLayout
                    title="添付画像"
                    subTitle={'※画像は3つまで添付可'}
                  >
                    <Grid container spacing={1}>
                      <Grid alignItems="center" item xs={12}>
                        <div className={classes.body}>
                          <input
                            accept="image/*"
                            className={classes.input}
                            id="contained-button-image"
                            type="file"
                            onChange={handleChangeImage}
                            disabled={isRestrictedRole}
                          />
                        </div>
                        {images &&
                          images.length > 0 &&
                          images.map((row, index) => (
                            <div className={classes.body} key={index}>
                              <Image
                                attachment={row}
                                handleDelete={handleDeleteImage(index)}
                                style={{ marginBottom: 10 }}
                                disabledIcon={isRestrictedRole}
                              />
                            </div>
                          ))}
                        {(images == null || images.length < 3) && (
                          <div className={classes.body}>
                            <label
                              htmlFor="contained-button-image"
                              className={classes.labelButton}
                            >
                              <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                color="primary"
                                component="span"
                                startIcon={<AddIcon />}
                                disabled={isRestrictedRole}
                              >
                                画像を追加
                              </Button>
                            </label>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </FormLayout>
                </Paper>
              </div>

              <div className={classes.body}>
                <Grid container spacing={2}>
                  <Grid alignItems="center" item xs={6}>
                    <Paper>
                      <FormLayout
                        title="添付動画"
                        subTitle={'※動画は1つまで添付可'}
                      >
                        <Grid item xs={12}>
                          <input
                            accept="video/*"
                            className={classes.input}
                            id="contained-button-movie"
                            type="file"
                            onChange={handleChangeMovie}
                            disabled={isRestrictedRole}
                          />
                        </Grid>
                        {movie ? (
                          <Movie
                            attachment={movie}
                            handleDelete={handleDeleteMovie}
                            disabledIcon={isRestrictedRole}
                          />
                        ) : (
                          <label htmlFor="contained-button-movie">
                            <Button
                              fullWidth
                              size="large"
                              variant="outlined"
                              color="primary"
                              component="span"
                              startIcon={<AddIcon />}
                              disabled={isRestrictedRole}
                            >
                              動画を追加
                            </Button>
                          </label>
                        )}
                      </FormLayout>
                    </Paper>
                  </Grid>
                  <Grid alignItems="center" item xs={6}>
                    <Paper>
                      <FormLayout
                        title="添付PDF"
                        subTitle={'※PDFは3つまで添付可'}
                      >
                        <Grid item xs={12}>
                          <input
                            accept="application/pdf"
                            className={classes.input}
                            id="contained-button-pdf"
                            type="file"
                            onChange={handleChangePdf}
                            disabled={isRestrictedRole}
                          />
                        </Grid>
                        {pdfs &&
                          pdfs.length > 0 &&
                          pdfs.map((row, index) => (
                            <div
                              className={index !== 0 && classes.itemContainer}
                              key={index}
                            >
                              <Pdf
                                attachment={row}
                                handleDelete={handleDeletePdf(index)}
                                disabledIcon={isRestrictedRole}
                              />
                            </div>
                          ))}
                        {(pdfs == null || pdfs.length < 3) && (
                          <div className={classes.body}>
                            <label htmlFor="contained-button-pdf">
                              <Button
                                fullWidth
                                size="large"
                                variant="outlined"
                                color="primary"
                                component="span"
                                startIcon={<AddIcon />}
                                disabled={isRestrictedRole}
                              >
                                PDFを追加
                              </Button>
                            </label>
                          </div>
                        )}
                      </FormLayout>
                    </Paper>
                  </Grid>
                </Grid>
              </div>

              <div className={classes.body}>
                <Paper>
                  <FormLayout title="配信日">
                    <Grid container spacing={2}>
                      <Grid alignItems="center" item xs={6}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeJa}
                        >
                          <KeyboardDatePicker
                            required
                            disableToolbar
                            inputVariant="filled"
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="startAt"
                            label="掲載開始日"
                            value={startAt}
                            onChange={handleChangeStartAt}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            error={validateStartAt.hasError}
                            helperText={validateStartAt.message}
                            disabled={isRestrictedRole}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6}>
                        <MuiPickersUtilsProvider
                          utils={DateFnsUtils}
                          locale={localeJa}
                        >
                          <KeyboardDatePicker
                            required
                            disableToolbar
                            inputVariant="filled"
                            format="yyyy/MM/dd"
                            margin="normal"
                            id="endAt"
                            label="掲載終了日"
                            value={endAt}
                            onChange={handleChangeEndAt}
                            KeyboardButtonProps={{
                              'aria-label': 'change date'
                            }}
                            error={validateEndAt.hasError}
                            helperText={validateEndAt.message}
                            disabled={isRestrictedRole}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                    </Grid>
                  </FormLayout>
                </Paper>
              </div>

              <div className={classes.body}>
                <Paper>
                  <FormLayout title="配信先">
                    <RadioGroup
                      row
                      aria-label="position"
                      name="position"
                      defaultValue={'0'}
                      value={assignFlg}
                      onChange={handleChangeAssign}
                    >
                      <Grid container spacing={3}>
                        <Grid alignItems="center" item xs={5}>
                          <FormControlLabel
                            value="0"
                            control={<Radio color="primary" />}
                            label="全件"
                            labelPlacement="start"
                            disabled={isRestrictedRole}
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <FormControlLabel
                            value="1"
                            control={<Radio color="primary" />}
                            label="指定配信"
                            labelPlacement="start"
                            disabled={isRestrictedRole}
                          />
                        </Grid>
                        {assignFlg === '1' && (
                          <Grid item xs={4}>
                            <Link href="#" onClick={handleOpenDialog}>
                              <Typography className={classes.linkText}>
                                (
                                {assignBuildings && assignBuildings.length > 0
                                  ? assignBuildings.length
                                  : 0}
                                件)
                              </Typography>
                            </Link>
                            <Button
                              className={classes.button}
                              variant="contained"
                              onClick={handleOpenDialog}
                              disabled={isRestrictedRole}
                            >
                              指定
                            </Button>
                          </Grid>
                        )}
                      </Grid>
                    </RadioGroup>
                  </FormLayout>
                </Paper>
              </div>

              {isOwnerFunctionEnabled && role12() && (
                <div className={classes.body}>
                  <Paper>
                    <div className={classes.section}>
                      <Grid container alignItems="center">
                        <Grid item>
                          <Typography>オーナーにも配信</Typography>
                        </Grid>
                        <Grid item>
                          <Switch
                            className={classes.switchButton}
                            checked={withOwner}
                            onChange={handleChangeWithOwner}
                            name="withOwner"
                            disabled={isRestrictedRole}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </div>
              )}

              <div className={classes.bottom}>
                <Grid container spacing={3}>
                  <Grid item xs={8} spacing={2}>
                    {id && (
                      <IconButton
                        className={classes.iconButton}
                        aria-label="search"
                        onClick={handleDeleteBulletinBoard}
                        disabled={isRestrictedRole}
                      >
                        <DeleteIcon />
                        削除
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      variant="contained"
                      className={classes.subButton}
                      onClick={handleCancel}
                      disabled={isRestrictedRole}
                    >
                      キャンセル
                    </Button>
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      className={classes.button}
                      disabled={isRestrictedRole}
                    >
                      保存
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
            <Dialog
              className={classes.dialog}
              onClose={handleCloseDialog}
              open={isOpen}
              maxWidth={false}
              scroll={'body'}
            >
              <AssignForm
                assignBuildings={assignBuildings}
                setAssignBuildings={setAssignBuildings}
                handleCloseDialog={handleCloseDialog}
              />
            </Dialog>
          </form>
        </FormProvider>
      )}
    </>
  )
}

const mapStateToProps = state => {}

const mapDispatchToProps = dispatch => {
  return {
    setLoadData: flag => {
      dispatch(setLoadData(flag))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(BulletinBoardForm)))
