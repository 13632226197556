import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import AddIcon from '@material-ui/icons/Add'
import List from './List'
import Pagination from 'components/common/Pagination'
import RadioButtons from 'components/common/RadioButtons'
import { PAGE_LIMIT } from 'utils/const'
import { CompanyUserStatusEnum, ScreenUrl } from 'utils/enum'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeSearchModalDialog,
  saveCompanyUserSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import FormModalDialog from './FormModalDialog'
import FormContent from './FormContent'
import ModalDialogFooter from './ModalDialogFooter'
import Loading from 'components/common/Loading'
import CommonChip from 'components/common/CommonChip'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  primaryButton: {
    color: theme.palette.c_blue.contrastText,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  }
}))

const buttonsData = [
  {
    value: CompanyUserStatusEnum.all.code,
    label: CompanyUserStatusEnum.all.value
  },
  {
    value: CompanyUserStatusEnum.active.code,
    label: CompanyUserStatusEnum.active.value
  },
  {
    value: CompanyUserStatusEnum.inactive.code,
    label: CompanyUserStatusEnum.inactive.value
  }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { role1 } = useRole()
  const isUnrestrictedRole = role1()

  const { pageId } = useParams()

  const [pageNumber, setPageNumber] = useState(isFinite(pageId) ? pageId : 1)
  const [selectedStatus, setSelectedStatus] = useState(
    location.state !== undefined && 'status' in location.state
      ? location.state.status
      : CompanyUserStatusEnum.all.code
  )
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined
      ? location.state
      : props.companyUserSearchParams
  )
  const [companyUserList, setCompanyUserList] = useState(initialState)
  const [companyGroupList, setCompanyGroupList] = useState([])
  const [roleList, setRoleList] = useState(initialState)
  const [roleItemList, setRoleItemList] = useState([])
  const [isLoading, setIsLoading] = useState(initialState)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  const handleSelectedButton = selectedStatus => {
    var params = Object.assign({}, searchCondition, {
      status: selectedStatus
    })
    if (!selectedStatus) {
      delete params['status']
    }
    params['pageNumber'] = 1
    props.saveCompanyUserSearchCondition(params)
    setSearchCondition(
      params,
      setSelectedStatus(selectedStatus),
      setPageNumber(1)
    )
  }

  async function fetchCompanyUserList(offset, limit) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'status') {
          filter['status'] = { eq: searchCondition[key] }
          setSelectedStatus(searchCondition[key])
          return
        }
        if (key == 'setting.company_user.company_group_id') {
          if (parseInt(searchCondition[key])) {
            filter['company_group_id'] = {
              eq: searchCondition[key]
            }
          }
        } else if (key == 'setting.company_user.name') {
          filter['name'] = {
            contains: searchCondition[key]
          }
        } else if (key == 'setting.company_user.display_name') {
          filter['display_name'] = {
            contains: searchCondition[key]
          }
        } else if (key == 'setting.company_user.username') {
          filter['username'] = {
            contains: searchCondition[key]
          }
        } else if (key == 'setting.company_user.role_id') {
          filter['company_group_role_id'] = {
            eq: searchCondition[key]
          }
        }
      }
    })
    filter['type_id'] = {
      eq: 1
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setCompanyUserList(result)
    }
  }

  const postData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const params = {}
    for (let entry of formData.entries()) {
      if (entry[0] === 'status') {
        if (entry[1] !== '') {
          setSelectedStatus(JSON.parse(entry[1]))
          params[entry[0]] = JSON.parse(entry[1])
        }
      } else if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    props.saveCompanyUserSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(
      params,
      setSelectedStatus(CompanyUserStatusEnum.all.code),
      setPageNumber(1)
    )
  }

  async function fetchCompanyGroupList() {
    var filter = {}
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter
      },
      false
    )
    if (result.error) {
      props.enqueueSnackbar('所属グループ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const l = [{ value: '', label: '' }]
      result.data.forEach(item => {
        l.push({ value: item.id, label: item.name })
      })
      setCompanyGroupList(l)
    }
  }

  async function fetchRoleList() {
    let result = await QueryHelper('getRoleList')
    if (result.error) {
      props.enqueueSnackbar('権限一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const roleItem = [{ value: '', label: '' }]
      result.data.forEach(item => {
        roleItem.push({ value: item.id, label: item.name })
      })
      setRoleItemList(roleItem)
      setRoleList(result)
    }
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchRoleList(),
      fetchCompanyUserList(offset, PAGE_LIMIT),
      fetchCompanyGroupList()
    ])
    setIsLoading(false)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.settingCompanyUserList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, selectedStatus, searchCondition, setCompanyUserList])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.settingCompanyUserList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      } else {
        setSearchCondition({ status: 1 })
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'status': {
        setSelectedStatus(CompanyUserStatusEnum.all.code)
        delete params[target]
        break
      }
      default: {
        delete params[target]
      }
    }
    params['pageNumber'] = 1
    props.saveCompanyUserSearchCondition(params)
    setSearchCondition(params, setPageNumber(1))
  }

  function isContainsSettingCompanyUserBySearchConditions() {
    const val = Object.keys(searchCondition).find(
      x =>
        x.match(/^setting.company_user.+/) ||
        (x === 'status' && searchCondition[x] !== 0)
    )
    return val !== null && val !== undefined
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsSettingCompanyUserBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'status': {
                    if (
                      searchCondition[key] != 0 &&
                      searchCondition[key] != undefined
                    ) {
                      if (searchCondition[key] == 1) {
                        label = '状態: 利用中'
                      } else if (searchCondition[key] == 2) {
                        label = '状態: 停止'
                      }
                    }
                    break
                  }
                  case 'setting.company_user.name': {
                    label = '氏名: ' + searchCondition[key]
                    break
                  }
                  case 'setting.company_user.display_name': {
                    label = '表示名: ' + searchCondition[key]
                    break
                  }
                  case 'setting.company_user.username': {
                    label = 'メールアドレス: ' + searchCondition[key]
                    break
                  }
                  case 'setting.company_user.company_group_id': {
                    for (let companyGroup of companyGroupList) {
                      if (companyGroup.value === Number(searchCondition[key])) {
                        label = '種別: ' + companyGroup.label
                      }
                    }
                    break
                  }
                  case 'setting.company_user.role_id': {
                    for (let roleItem of roleItemList) {
                      if (roleItem.value === Number(searchCondition[key])) {
                        label = 'グループ権限: ' + roleItem.label
                      }
                    }
                    break
                  }
                  default: {
                    break
                  }
                }
                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
        <Grid container spacing={3}>
          <SearchConditionChips />
          <Grid item xs className={classes.tableTips}>
            <Button
              onClick={e =>
                history.push({
                  pathname: `/setting/company-user/new`,
                  state: searchCondition
                })
              }
              variant="contained"
              startIcon={<AddIcon />}
              disableElevation
              className={classes.primaryButton}
              disabled={!isUnrestrictedRole}
            >
              利用者を追加
            </Button>
          </Grid>
          <Grid item xs={5} className={classes.tableTips}>
            <RadioButtons
              current={selectedStatus}
              handleSelectedButton={handleSelectedButton}
              buttonsData={buttonsData}
            />
          </Grid>
          <SearchSaveAndCallButton />
          <Grid item xs className={classes.tableTips}>
            <TablePagination
              rowsPerPageOptions={[]}
              component="div"
              count={companyUserList.paging.count}
              rowsPerPage={PAGE_LIMIT}
              page={pageNumber - 1}
              onChangePage={handleChangePage}
            />
          </Grid>
        </Grid>
        {!isLoading && companyUserList ? (
          <Paper className={classes.root} style={{ marginTop: 20 }}>
            <div className={classes.tableWrapper}>
              <List
                roleList={roleList.data}
                companyUserList={companyUserList.data}
                searchCondition={searchCondition}
              />
            </div>
          </Paper>
        ) : (
          <Loading isLoading={isLoading} marginTop={20} />
        )}
        <Pagination
          handlePageNumber={handleCustomChangePage}
          pageNumber={pageNumber}
          count={companyUserList.paging.count}
          rowsPerPage={PAGE_LIMIT}
        />
      </div>
      <FormModalDialog
        content={
          <FormContent
            searchCondition={searchCondition}
            roleItemList={roleItemList}
          />
        }
        footer={<ModalDialogFooter />}
        postData={postData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.settingCompanyUserList}
            searchConditions={searchCondition}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    companyUserSearchParams: state.saveSearchCondition.companyUser
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveCompanyUserSearchCondition: parameter => {
      dispatch(saveCompanyUserSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
