import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import List from './List'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  closeSearchModalDialog,
  handleClear,
  saveBuildingSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import { ScreenUrl } from 'utils/enum'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import Loading from 'components/common/Loading'
import CommonChip from 'components/common/CommonChip'
import { SearchConditionContext } from 'context/SearchConditionContext'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()

  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)

  const { isLoad, setLoadData } = props

  const [buildingList, setBuildingList] = useState(initialState)
  const [knrDivList, setKnrDivList] = useState({})
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : props.buildingSearchParams
  )
  const [tysList, setTysList] = useState(null)
  const [prefectureList, setPrefectureList] = useState(null)
  const [cityList, setCityList] = useState(null)
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )
  const [{}, dispatchSearchCondition] = React.useContext(SearchConditionContext)

  const handleChangePage = (event, newPage) => {
    searchCondition['pageNumber'] = newPage + 1
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    searchCondition['pageNumber'] = newPage
    setPageNumber(newPage)
  }

  async function fetchBuildingList(offset, limit) {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'management.building.TTY_NAME') {
          filter['TTY_NAME'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.building.TTY_ID') {
          filter['TTY_ID'] = {
            eq: Number(searchCondition[key])
          }
        }
        if (key == 'management.building.TTY_KNR_NO') {
          filter['TTY_KNR_NO'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.building.TTY_TYS_CD') {
          filter['TTY_TYS_CD'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.building.TTY_PRF_CD') {
          filter['TTY_PRF_CD'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.building.TTY_CTY_CD') {
          filter['TTY_CTY_CD'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.building.TTY_KNR_DIV') {
          filter['TTY_KNR_DIV'] = {
            eq: searchCondition[key]
          }
        }
        if (key == 'management.building.TTY_KNRKEITAI_DIV') {
          filter['TTY_KNRKEITAI_DIV'] = {
            eq: searchCondition[key]
          }
        }
      }
    })
    filter['is_management'] = {
      eq: 1
    }

    if (orderBy && Object.keys(orderBy).length > 0) {
      filter.orderBy = [orderBy]
    }

    // CSVDL用に検索条件保存
    dispatchSearchCondition({
      type: 'SET',
      payload: { buildingSearchCondition: filter }
    })

    let result = await QueryHelper(
      'getBuildingList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('物件一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setBuildingList(result)
      await Promise.all([
        fetchMstTateyaKnrDivList(),
        fetchMstTateyaKnrkeitaiDivList()
      ])
    }
    // setLoadData(false);
  }

  const fetchMstTateyaKnrDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKNRDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKNRDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knr_cd] = item.knr_name
      })
      setKnrDivList(l)
    }
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKnrkeitaiDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knrkeitai_cd] = item.knrkeitai_name
      })
      setKnrkeitaiDivList(l)
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    const params = {}
    for (let entry of formData.entries()) {
      if (entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    props.saveBuildingSearchCondition(params)
    props.closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(params, setPageNumber(1), setOrderBy({}))
  }

  async function fetchTysList() {
    await API.graphql(graphqlOperation(queries.getMSTTateyaTysList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaTysList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.tys_cd, label: item.tys_name })
          })
          setTysList(l)
        } else {
          props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchPrefectureList() {
    await API.graphql(graphqlOperation(queries.getPrefectureList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getPrefectureList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.prf_cd, label: item.name })
          })
          setPrefectureList(l)
        } else {
          props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  async function fetchCityList(prfCd) {
    await API.graphql(
      graphqlOperation(queries.getCityList, {
        filter: { prf_cd: { eq: prfCd } }
      })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getCityList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.cty_cd, label: item.name })
          })
          setCityList(l)
        } else {
          props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchBuildingList(offset, PAGE_LIMIT),
      tysList ? '' : fetchTysList(),
      prefectureList ? '' : fetchPrefectureList(),
      searchCondition && 'management.building.TTY_PRF_CD' in searchCondition
        ? fetchCityList(searchCondition['management.building.TTY_PRF_CD'])
        : ''
    ])
    setIsLoading(false)
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchCondition['orderBy'] = sortParam
    searchCondition['pageNumber'] = 1
    setPageNumber(1)
  }

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `${ScreenUrl.managementBuildingList}${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, setBuildingList, searchCondition, orderBy])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.managementBuildingList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => event => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'management.building.TTY_PRF_CD': {
        delete params[target]
        delete params['management.building.TTY_CTY_CD']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    props.saveBuildingSearchCondition(params)
    setSearchCondition(params, setPageNumber(1), setOrderBy({}))
  }

  function isContainsManagementBuildingBySearchConditions() {
    const val = Object.keys(searchCondition).find(x =>
      x.match(/^management.building.+/)
    )
    if (val === undefined) {
      return Object.keys(searchCondition).indexOf('selectedStatus') !== -1
    }
    return true
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsManagementBuildingBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'management.building.TTY_NAME': {
                    label = '物件名: ' + searchCondition[key]
                    break
                  }
                  case 'management.building.TTY_ID': {
                    label = 'ID: ' + searchCondition[key]
                    break
                  }
                  case 'management.building.TTY_KNR_NO': {
                    label = '建物番号: ' + searchCondition[key]
                    break
                  }
                  case 'management.building.TTY_TYS_CD': {
                    if (tysList && tysList.length > 0) {
                      for (let tys of tysList) {
                        if (tys.value === Number(searchCondition[key])) {
                          label = '種別: ' + tys.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'management.building.TTY_PRF_CD': {
                    if (prefectureList && prefectureList.length > 0) {
                      for (let pref of prefectureList) {
                        if (pref.value === Number(searchCondition[key])) {
                          label = '都道府県: ' + pref.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'management.building.TTY_CTY_CD': {
                    if (cityList && cityList.length > 0) {
                      for (let city of cityList) {
                        if (city.value === Number(searchCondition[key])) {
                          label = '市区町村: ' + city.label
                          break
                        }
                      }
                    }
                    break
                  }
                  case 'management.building.TTY_KNR_DIV': {
                    if (knrDivList && Object.keys(knrDivList).length > 0) {
                      label =
                        '管理区分: ' + knrDivList[Number(searchCondition[key])]
                    }
                    break
                  }
                  case 'management.building.TTY_KNRKEITAI_DIV': {
                    if (
                      knrkeitaiDivList &&
                      Object.keys(knrkeitaiDivList).length > 0
                    ) {
                      label =
                        '管理形態: ' +
                        knrkeitaiDivList[Number(searchCondition[key])]
                    }
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}></Grid>
        <Grid item xs={5} className={classes.tableTips}></Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={buildingList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && buildingList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              buildingList={buildingList.data}
              knrDivList={knrDivList}
              knrkeitaiDivList={knrkeitaiDivList}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={buildingList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        // title="物件の検索"
        // description="※削除すると、各所から表示されなくなります。"
        content={<SearchFormContent searchCondition={searchCondition} />}
        footer={<SearchModalDialogFooter />}
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.managementBuildingList}
            searchConditions={searchCondition}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    buildingSearchParams: state.saveSearchCondition.building
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    saveBuildingSearchCondition: parameter => {
      dispatch(saveBuildingSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
