import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { QueryHelper } from 'utils/api.utils'
import * as queries from 'graphql/queries'
import TransferList from './TransferList'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import Dropdown from 'components/common/Form/Dropdown'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Button from '@material-ui/core/Button'
import { withSnackbar } from 'notistack'
import API, { graphqlOperation } from '@aws-amplify/api'
import { connect } from 'react-redux'
import { ScreenUrl } from 'utils/enum'
import { closeSearchSaveModalDialog } from 'actions/Actions'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import { useRole } from 'hooks/useRole'

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  topWrapper: {
    flex: 1,
    display: 'flex'
  },
  top: {
    width: '300px',
    height: 490,
    marginTop: '16px',
    marginLeft: '16px',
    marginRight: '32px',
    backgroundColor: '#fff'
  },
  top2: {
    width: 'auto',
    backgroundColor: '#fff'
  },
  primaryButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.lightest
    }
  },
  subButtonWrapper: {
    textAlign: 'end'
  }
}))

function AssignForm(props) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()
  const [assign, setAssign] = useState(null)
  const [buildings, setBuildings] = useState(null)
  const [searchCondition, setSearchCondition] = useState({})
  const [mstTateyaTysList, setMstTateyaTysList] = useState(null)
  const [prefectureList, setPrefectureList] = useState(null)
  const [cityList, setCityList] = useState([
    {
      label: '都道府県を選択してください',
      value: ''
    }
  ])
  const [mstTateyaKnrDivList, setMstTateyaKnrDivList] = useState(null)
  const [mstTateyaKnrkeitaiDivList, setMstTateyaKnrkeitaiDivList] = useState(
    null
  )

  const [isLoading, setIsLoading] = React.useState(false)

  useEffect(() => {
    if (Object.keys(searchCondition).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    fetchData(props.assignBuildings)
  }, [props])

  useEffect(() => {
    if (searchCondition.TTY_PRF_CD) {
      if (!searchCondition.TTY_CTY_CD) {
        searchCondition.TTY_CTY_CD = null
      }
      fetchCityList(searchCondition.TTY_PRF_CD)
    }
  }, [searchCondition.TTY_PRF_CD])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.bulletinBoardEdit
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const fetchData = async assignBuildings => {
    await Promise.all([
      fetchAssignBuildings(assignBuildings),
      fetchMstTateyaTysList(),
      fetchPrefectureList(),
      fetchMstTateyaKnrDivList(),
      fetchMstTateyaKnrkeitaiDivList()
    ])
  }

  const fetchAssignBuildings = async assignBuildings => {
    if (!assignBuildings || assignBuildings.length <= 0) {
      setAssign([])
      return
    }
    const ttyIds = []
    for (let building of assignBuildings) {
      if (!ttyIds.includes(building.tty_id)) {
        ttyIds.push(building.tty_id)
      }
    }
    const result = await QueryHelper('getBuildingList', {
      filter: { TTY_ID: { contains: ttyIds } }
    })
    if (!result.error) {
      setAssign(result.data)
    } else {
      props.enqueueSnackbar(`配信対象の物件データを取得できませんでした。`)
    }
  }

  const fetchMstTateyaTysList = async () => {
    API.graphql(graphqlOperation(queries.getMSTTateyaTysList, {})).then(
      response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaTysList
          const items = [{ value: '', label: '' }]
          result.data.forEach(item => {
            items.push({ value: item.tys_cd, label: item.tys_name })
          })
          setMstTateyaTysList(items)
        } else {
          props.enqueueSnackbar(`建物種別データを取得できませんでした。`)
        }
      }
    )
  }

  const fetchPrefectureList = async () => {
    const result = await QueryHelper('getPrefectureList', {})
    if (!result.error) {
      const items = [{ value: '', label: '' }]
      result.data.forEach(item => {
        items.push({ value: item.prf_cd, label: item.name })
      })
      setPrefectureList(items)
    } else {
      props.enqueueSnackbar(`都道府県データを取得できませんでした。`)
    }
  }

  const fetchCityList = async prefCd => {
    const result = await QueryHelper('getCityList', {
      filter: { prf_cd: { eq: prefCd } }
    })
    if (!result.error) {
      const items = [{ value: '', label: '' }]
      result.data.forEach(item => {
        items.push({ value: item.cty_cd, label: item.name })
      })
      setCityList(items)
    } else {
      props.enqueueSnackbar(`市区町村データを取得できませんでした。`)
    }
  }

  const fetchMstTateyaKnrDivList = async () => {
    API.graphql(graphqlOperation(queries.getMSTTateyaKNRDivList, {})).then(
      response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaKNRDivList
          const items = [{ value: '', label: '' }]
          result.data.forEach(item => {
            items.push({ value: item.knr_cd, label: item.knr_name })
          })
          setMstTateyaKnrDivList(items)
        } else {
          props.enqueueSnackbar(`管理区分データを取得できませんでした。`)
        }
      }
    )
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    API.graphql(
      graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {})
    ).then(response => {
      const errors = response.errors
      if (!errors) {
        const result = response.data.getMSTTateyaKnrkeitaiDivList
        const items = [{ value: '', label: '' }]
        result.data.forEach(item => {
          items.push({ value: item.knrkeitai_cd, label: item.knrkeitai_name })
        })
        setMstTateyaKnrkeitaiDivList(items)
      } else {
        props.enqueueSnackbar(`管理区分データを取得できませんでした。`)
      }
    })
  }

  const handleChange = event => {
    if (event.target.name === 'TTY_PRF_CD') {
      setCityList([
        {
          label: '都道府県を選択してください',
          value: ''
        }
      ])
      if (searchCondition.TTY_CTY_CD) {
        delete searchCondition.TTY_CTY_CD
      }
    }
    setSearchCondition({
      ...searchCondition,
      [event.target.name]: event.target.value
    })
    if (event.target.value === '') {
      delete searchCondition[event.target.name]
    }
  }

  const handleCancel = event => {
    setSearchCondition({})
    setCityList([
      {
        label: '都道府県を選択してください',
        value: ''
      }
    ])
    setAssign(null)
  }

  const handleSearch = async () => {
    setIsLoading(true)
    const params = { filter: {} }

    const filterConditions = {
      TTY_NAME: 'contains',
      TTY_TYS_CD: 'eq',
      TTY_PRF_CD: 'eq',
      TTY_CTY_CD: 'eq',
      TTY_KNR_DIV: 'eq',
      TTY_KNRKEITAI_DIV: 'eq'
    }

    Object.keys(searchCondition).forEach(key => {
      const conditionType = filterConditions[key]
      if (
        conditionType &&
        searchCondition[key] !== '' &&
        searchCondition[key] !== undefined &&
        searchCondition[key] !== null
      ) {
        params.filter[key] = { [conditionType]: searchCondition[key] }
      }
    })

    // is_managementの指定がない場合は全ての物件を取得するため、チェックがない場合のみ指定する
    if (!searchCondition.is_management) {
      params.filter.is_management = { eq: true }
    }
    const result = await QueryHelper('getBuildingList', params)
    if (!result.error) {
      setBuildings(result.data)
    } else {
      props.enqueueSnackbar(`物件データを取得できませんでした。`)
    }
    setIsLoading(false)
  }

  return (
    <>
      <div className={classes.root}>
        <div className={classes.topWrapper}>
          <div className={classes.top}>
            <div>
              <Grid container spacing={2}>
                <SearchSaveAndCallButton xs={12} />
                <Grid item xs={12}>
                  <TextField
                    id="TTY_NAME"
                    name="TTY_NAME"
                    label="物件名"
                    defaultValue={searchCondition.TTY_NAME}
                    value={searchCondition.TTY_NAME}
                    fullWidth
                    variant="filled"
                    autoComplete="TTY_NAME"
                    onChange={handleChange}
                    disabled={isRestrictedRole}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    name="TTY_TYS_CD"
                    label={'建物種別'}
                    onChange={handleChange}
                    defaultValue={searchCondition.TTY_TYS_CD}
                    items={mstTateyaTysList}
                    disabled={isRestrictedRole}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    name="TTY_PRF_CD"
                    label={'都道府県'}
                    onChange={handleChange}
                    defaultValue={searchCondition.TTY_PRF_CD}
                    items={prefectureList}
                    disabled={isRestrictedRole}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    name="TTY_CTY_CD"
                    label={'市区町村'}
                    onChange={handleChange}
                    defaultValue={searchCondition.TTY_CTY_CD}
                    items={cityList}
                    disabled={isRestrictedRole}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    name="TTY_KNR_DIV"
                    label={'管理区分'}
                    onChange={handleChange}
                    defaultValue={searchCondition.TTY_KNR_DIV}
                    items={mstTateyaKnrDivList}
                    disabled={isRestrictedRole}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropdown
                    name="TTY_KNRKEITAI_DIV"
                    label={'管理形態'}
                    onChange={handleChange}
                    defaultValue={searchCondition.TTY_KNRKEITAI_DIV}
                    items={mstTateyaKnrkeitaiDivList}
                    disabled={isRestrictedRole}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        name="is_management"
                        checked={searchCondition.is_management || false}
                        onChange={() => {
                          setSearchCondition({
                            ...searchCondition,
                            is_management: !searchCondition.is_management
                          })
                        }}
                        value={searchCondition.is_management || false}
                        disabled={isRestrictedRole}
                      />
                    }
                    label="無効物件含む"
                  />
                </Grid>
                <Grid item xs={9} className={classes.subButtonWrapper}>
                  <Button
                    variant="contained"
                    onClick={handleCancel}
                    disabled={isRestrictedRole}
                  >
                    条件クリア
                  </Button>
                </Grid>
                <Grid item xs={3}>
                  <Button
                    className={classes.primaryButton}
                    variant="contained"
                    color="primary"
                    onClick={handleSearch}
                    disabled={isRestrictedRole}
                  >
                    検索
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={classes.top2}>
            <TransferList
              isLoading={isLoading}
              assign={assign}
              setAssignBuildings={props.setAssignBuildings}
              buildings={buildings}
              handleCloseDialog={props.handleCloseDialog}
            />
          </div>
          <SearchSaveModalDialog
            content={
              <SearchSaveContent
                screen={ScreenUrl.bulletinBoardEdit}
                searchConditions={searchCondition}
                setSearchConditions={setSearchCondition}
                closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
              />
            }
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(AssignForm))
