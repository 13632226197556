export const fullAddress = mst_tateya => {
  let s =
    '〒' +
    mst_tateya.TTY_ZIPCODE +
    ' ' +
    mst_tateya.TTY_PRF_NAME +
    ' ' +
    mst_tateya.TTY_CTY_NAME +
    ' ' +
    mst_tateya.TTY_TWN_NAME
  if (mst_tateya.TTY_ADDR_01) {
    s = s + ' ' + mst_tateya.TTY_ADDR_01
  }
  if (mst_tateya.TTY_ADDR_02) {
    s = s + ' ' + mst_tateya.TTY_ADDR_02
  }
  return s
}

export const fullFloor = mst_tateya => {
  return (
    '地上' +
    mst_tateya.TTY_GROUND_FLOOR_NUM +
    '階 地下' +
    mst_tateya.TTY_UGGROUND_FLOOR_NUM +
    '階'
  )
}

export const formatDate = (date, format) => {
  format = format.replace(/yyyy/g, date.getFullYear())
  format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2))
  format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2))
  format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2))
  format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2))
  format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2))
  format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3))
  return format
}

export const parseDate = dateStr => {
  return new Date(dateStr)
}

export const getKnrDiv = (mst_tateya, knrDivList) => {
  if (!mst_tateya.TTY_KNR_DIV) {
    return ''
  }
  var arr = []
  for (let value of mst_tateya.TTY_KNR_DIV) {
    arr.push(value in knrDivList ? knrDivList[value] : '')
  }
  return arr.join(',')
}

export const getKnrkeitaiDiv = (mst_tateya, knrkeitaiDivList) => {
  if (
    mst_tateya.TTY_KNRKEITAI_DIV != null &&
    mst_tateya.TTY_KNRKEITAI_DIV in knrkeitaiDivList
  ) {
    return knrkeitaiDivList[mst_tateya.TTY_KNRKEITAI_DIV]
  }
  return ''
}
