import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import { connect } from 'react-redux'
import {
  changeSearchCondition,
  closeSearchModalDialog,
  handleClear,
  setSelectDeliveryListId,
  saveMessageSearchCondition,
  saveOwnerMessageSearchCondition,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import { withSnackbar } from 'notistack'
import {
  ReplyState,
  ThreadState,
  ScreenUrl,
  MessageCategoryType
} from 'utils/enum'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import List from './List'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import Loading from 'components/common/Loading'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import CommonChip from 'components/common/CommonChip'
import { fromCode } from 'utils/enum.utils'
import { SearchConditionContext } from 'context/SearchConditionContext'
import CheckButtons from 'components/common/CheckButtons'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'
import { useCustomerOrOwner } from '../../hooks/useCustomerOrOwner'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  checkbox: {
    '& .MuiFormControlLabel-label': {
      color: '#2C3F58',
      fontFamily: 'Hiragino Sans',
      fontSize: '14px'
    }
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  }
}))

const buttonsData = [
  { value: ThreadState.all.code, label: ThreadState.all.value },
  { value: ThreadState.open.code, label: ThreadState.open.value },
  { value: ThreadState.inProgress.code, label: ThreadState.inProgress.value },
  { value: ThreadState.pending.code, label: ThreadState.pending.value },
  { value: ThreadState.complete.code, label: ThreadState.complete.value }
]

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pageId } = useParams()
  const {
    useCheckOwnerPath,
    useChangeStorageKey,
    useCheckSearchConditions
  } = useCustomerOrOwner()
  const isOwnerPath = useCheckOwnerPath()
  const storageKeyList = useChangeStorageKey()

  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const handleChangePage = (event, newPage) => {
    searchConditions[storageKeyList.pageNumber] = newPage + 1
    setPageNumber(newPage + 1)
  }
  const handleCustomChangePage = newPage => {
    searchConditions[storageKeyList.pageNumber] = newPage
    setPageNumber(newPage)
  }
  const [threadList, setThreadList] = useState(initialState)
  const [parentCategoryList, setParentCategoryList] = useState(null)
  const [childCategoryList, setChildCategoryList] = useState(null)
  const [companyGroupList, setCompanyGroupList] = useState(null)
  const [fetchedCompanyGroupList, setFetchedCompanyGroupList] = useState(null)
  const [companyUserList, setCompanyUserList] = useState(null)
  const [fetchedCompanyUserList, setFetchedCompanyUserList] = useState(null)
  const [companyUserMap, setCompanyUserMap] = useState(null)
  const [bulkDeliveryListName, setBulkDeliveryListName] = useState(null)
  const [searchConditions, setSearchConditions] = useState(
    location.state !== undefined
      ? location.state
      : props.selectDeliveryListId &&
        props.selectDeliveryListId.selectDeliveryListId
      ? {
          [storageKeyList.bulkDeliveryList]:
            props.selectDeliveryListId.selectDeliveryListId
        }
      : isOwnerPath
      ? props.ownerMessageSearchParams
      : props.messageSearchParams
  )
  const hasSearchConditions = useCheckSearchConditions(searchConditions)
  const [searchConditionErrors, setSearchConditionErrors] = useState({})
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )
  const [selectedStatus, setSelectedStatus] = useState(
    searchConditions[storageKeyList.threadState]
      ? searchConditions[storageKeyList.threadState]
      : [ThreadState.all.code]
  )
  const handleSelectedButton = selected => {
    if (selected === ThreadState.all.code) {
      setSelectedStatus([ThreadState.all.code])
      setSearchConditions(
        [ThreadState.all.code],
        setPageNumber(1),
        setOrderBy({})
      )
      return
    }
    let newSelectedStatus = []
    if (selectedStatus) {
      newSelectedStatus = newSelectedStatus.concat(selectedStatus)
    }
    if (newSelectedStatus.includes(selected)) {
      const idx = newSelectedStatus.indexOf(selected)
      newSelectedStatus.splice(idx, 1)
      if (newSelectedStatus.length > 0) {
        setSearchConditions({
          ...searchConditions,
          [storageKeyList.threadState]: newSelectedStatus
        })
      } else {
        setSelectedStatus([ThreadState.all.code])
        const messageKeyCount = Object.keys(searchConditions).filter(
          key => key.indexOf('message') !== -1
        ).length
        if (messageKeyCount > 1) {
          setSearchConditions({
            ...searchConditions,
            [storageKeyList.threadState]: ThreadState.all.code
          })
        } else {
          setSearchConditions(
            [ThreadState.all.code],
            setPageNumber(1),
            setOrderBy({})
          )
        }
        return
      }
    } else {
      newSelectedStatus.push(selected)
      if (newSelectedStatus.includes(ThreadState.all.code)) {
        newSelectedStatus = newSelectedStatus.filter(value => {
          return value !== ThreadState.all.code
        })
      }
      setSearchConditions({
        ...searchConditions,
        [storageKeyList.threadState]: newSelectedStatus
      })
    }
    searchConditions[storageKeyList.pageNumber] = 1
    setSelectedStatus(newSelectedStatus, setPageNumber(1), setOrderBy({}))
  }

  const [isLoad, setIsLoad] = useState(false)
  const [isReply, setIsReply] = useState(
    location.state !== undefined &&
      location.state[storageKeyList.replyState] === 1
      ? true
      : false
  )
  const handleChangeIsReply = () => {
    if (!isReply) {
      const params = searchConditions
      params[storageKeyList.replyState] = ReplyState.noReply.code
      params[storageKeyList.pageNumber] = 1
      isOwnerPath
        ? props.saveOwnerMessageSearchCondition(params)
        : props.saveMessageSearchCondition(params)
      setSearchConditions(params, setPageNumber(1), setOrderBy({}))
    } else {
      const params = searchConditions
      delete searchConditions[storageKeyList.replyState]
      params[storageKeyList.pageNumber] = 1
      isOwnerPath
        ? props.saveOwnerMessageSearchCondition(params)
        : props.saveMessageSearchCondition(params)
      setSearchConditions(params, setPageNumber(1), setOrderBy({}))
    }
    setIsReply(!isReply)
  }

  const [_, dispatchSearchCondition] = React.useContext(SearchConditionContext)

  async function fetchThreadList(threadStateId, offset, limit) {
    const params = {}
    Object.keys(searchConditions).forEach(function(key) {
      if (searchConditions[key]) {
        switch (key) {
          case storageKeyList.threadState: {
            if (searchConditions[storageKeyList.threadState] == 0) {
              break
            }
            const threadState = searchConditions[key]
            if (threadState && threadState.length > 0) {
              setSelectedStatus(searchConditions[key])
              const threadStateParam = []
              for (let i of threadState) {
                if (i === 3) {
                  threadStateParam.push(3)
                  threadStateParam.push(5)
                } else {
                  threadStateParam.push(i)
                }
              }
              params.thread_state_id = { contains: threadStateParam }
            }
            break
          }
          case storageKeyList.replyState: {
            if (searchConditions[key] != 0 && searchConditions[key] != '') {
              const isReply = searchConditions[key] == ReplyState.Replied.code
              setIsReply(!isReply)
              params.is_reply = { eq: isReply }
            }
            break
          }
          case storageKeyList.id: {
            params.id = { eq: Number(searchConditions[key]) }
            break
          }
          case storageKeyList.residentName: {
            params.resident_name = { eq: searchConditions[key] }
            break
          }
          case storageKeyList.ttyName: {
            params.TTY_NAME = { contains: searchConditions[key] }
            break
          }
          case storageKeyList.parentCategory: {
            if (searchConditions[key] != 0) {
              params.large_category_id = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.childCategory: {
            if (searchConditions[key] != 0) {
              params.medium_category_id = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.firstTransceiverState: {
            if (searchConditions[storageKeyList.firstTransceiverState] == 2) {
              params.first_sent = { eq: 1 }
            } else {
              params.first_sent = {
                eq: 0
              }
            }
            break
          }
          case storageKeyList.createdAtStrDateFrom: {
            const d = new Date(searchConditions[key])
            if (!isNaN(d.getTime())) {
              params.start_date_from = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.createdAtStrDateTo: {
            const d = new Date(searchConditions[key])
            if (!isNaN(d.getTime())) {
              params.start_date_to = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.exchangedAtStrDateFrom: {
            const d = new Date(searchConditions[key])
            if (!isNaN(d.getTime())) {
              params.last_date_from = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.exchangedAtStrDateTo: {
            const d = new Date(searchConditions[key])
            if (!isNaN(d.getTime())) {
              params.last_date_to = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.assignedCompanyGroup: {
            if (searchConditions[key] != 0) {
              params.officer_group_id = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.assignedCompanyUser: {
            if (searchConditions[key] != 0) {
              params.officer_user_id = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.exchangedCompanyUser: {
            if (searchConditions[key] != 0) {
              params.responder_id = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.bulkDeliveryList: {
            if (searchConditions[key] != 0) {
              params.list_id = { eq: searchConditions[key] }
            }
            break
          }
          case storageKeyList.bulkDeliveryListName: {
            params.list_name = { contains: searchConditions[key] }
            break
          }
          case storageKeyList.isArranged: {
            if (searchConditions[key] == 1) {
              params.is_arranged = { eq: true }
            } else {
              params.is_arranged = { ne: true }
            }
            break
          }
          case storageKeyList.isBulkDelivery: {
            if (searchConditions[key] == 1) {
              params.is_bulk_delivery = { eq: true }
            } else {
              params.is_bulk_delivery = { ne: true }
            }
            break
          }
        }
      }
    })

    if (orderBy && Object.keys(orderBy).length > 0) {
      params.orderBy = [orderBy]
    } else {
      params.orderBy = [{ column: 'updated_at', sorted: 'desc' }]
    }

    params.is_owner = isOwnerPath

    // CSVDL用に検索条件保存
    dispatchSearchCondition({
      type: 'SET',
      payload: { threadSearchCondition: params }
    })

    let result = await QueryHelper(
      'getThreadListBySearchCondition',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      props.enqueueSnackbar('メッセージ情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setThreadList(result)
    }
  }

  useEffect(() => {
    if (Object.keys(searchConditions).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: isOwnerPath
        ? `${ScreenUrl.ownerMessageList}${pageNumber}`
        : `${ScreenUrl.messageList}${pageNumber}`,
      state: searchConditions
    })
    fetchData(offset)
    isOwnerPath
      ? props.saveOwnerMessageSearchCondition(searchConditions)
      : props.saveMessageSearchCondition(searchConditions)
    props.setSelectDeliveryListId({
      selectDeliveryListId: null
    })
  }, [
    pageNumber,
    setPageNumber,
    selectedStatus,
    isReply,
    setThreadList,
    searchConditions,
    orderBy
  ])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: isOwnerPath ? ScreenUrl.ownerMessageList : ScreenUrl.messageList
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchConditions(JSON.parse(defaultSearchCondition))
      }
    }
  }

  const fetchData = async offset => {
    setIsLoad(true)
    await Promise.all([
      fetchThreadList(selectedStatus, offset, PAGE_LIMIT),
      parentCategoryList && childCategoryList ? '' : fetchCategoryList(),
      companyGroupList ? '' : fetchCompanyGroupList(0, 0),
      companyUserList ? '' : fetchCompanyUserList(0, null),
      searchConditions[storageKeyList.bulkDeliveryList]
        ? fetchBulkDeliveryList()
        : ''
    ])
    setIsLoad(false)
  }

  const fetchCategoryList = async () => {
    const categoryType = isOwnerPath
      ? MessageCategoryType.owner.code
      : MessageCategoryType.customer.code
    const result = await QueryHelper('getLargeCategoryAndMediumCategory', {
      category_type: categoryType
    })
    if (!result.error) {
      const parents = [{ value: 0, label: '' }]
      const children = { 0: [] }
      for (let row1 of result) {
        parents.push({
          value: row1.large_categories.id,
          label: row1.large_categories.name
        })
        const elements = [{ value: 0, label: '' }]
        if (!isOwnerPath) {
          for (let row2 of row1.medium_categories) {
            elements.push({
              value: row2.id,
              label: row2.name
            })
          }
        }
        children[row1.large_categories.id] = elements
      }
      setParentCategoryList(parents)
      setChildCategoryList(children)
    } else {
      props.enqueueSnackbar(`カテゴリーを取得できませんでした。`)
    }
  }

  const fetchCompanyGroupList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyGroupList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('グループ一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const companyGroupItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.role_id != 5 && item.status === 1) {
            const companyGroupItem = {
              value: item.id,
              label: item.name
            }
            companyGroupItems.push(companyGroupItem)
          }
        }
      }
      setCompanyGroupList(companyGroupItems)
      setFetchedCompanyGroupList(result.data)
    }
  }

  const fetchCompanyUserList = async (offset, limit) => {
    let filter = {}
    filter['status'] = {
      contains: [1, 2, 3]
    }
    let result = await QueryHelper(
      'getCompanyUserList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      offset == 0 ? true : false
    )
    if (result.error) {
      props.enqueueSnackbar('利用者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const userMap = new Map()
      const companyUserItems = [{ value: 0, label: '' }]
      if (result.data.length > 0) {
        for (let item of result.data) {
          if (item.company_user.status === 1) {
            const companyUserItem = {
              value: item.company_user.id,
              label: item.company_user.name
            }
            companyUserItems.push(companyUserItem)
            if (!userMap.has(item.company_group.id)) {
              userMap.set(item.company_group.id, [companyUserItem])
            } else {
              const companyUserItemArray = userMap.get(item.company_group.id)
              companyUserItemArray.push(companyUserItem)
            }
          }
        }
      }
      setCompanyUserList(companyUserItems)
      setCompanyUserMap(userMap)
      setFetchedCompanyUserList(result.data)
    }
  }

  const fetchBulkDeliveryList = async () => {
    // 送信済みのもののみ表示
    let params = {}
    params.status = 'SENT'
    params.id = { eq: searchConditions[storageKeyList.bulkDeliveryList] }
    await API.graphql(
      graphqlOperation(queries.getBulkDeliveryList, { filter: params })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getBulkDeliveryList
          setBulkDeliveryListName(result.data[0].name)
        } else {
          props.enqueueSnackbar('配信リストを取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(() => {
        props.enqueueSnackbar('配信リストを取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[0] === storageKeyList.threadState) {
        if (entry[1] !== '') {
          const array = []
          for (let i of JSON.parse(entry[1])) {
            array.push(i.value)
          }
          setSelectedStatus(array)
          params[entry[0]] = array
        }
      } else if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    props.changeSearchCondition(params)
    isOwnerPath
      ? props.saveOwnerMessageSearchCondition(params)
      : props.saveMessageSearchCondition(params)
    props.closeSearchModalDialog()
    params[pageNumber] = 1
    if (params[storageKeyList.threadState]) {
      if (params[storageKeyList.threadState].length == 0) {
        delete params[storageKeyList.threadState]
        setSelectedStatus([ThreadState.all.code])
      }
    }
    setSearchConditions(params, setPageNumber(1), setOrderBy({}))
  }

  function validateSearchCondition(params) {
    const valid = {}

    if (
      params[storageKeyList.createdAtStrDateFrom] &&
      !params[storageKeyList.createdAtStrDateTo]
    ) {
      valid[storageKeyList.createdAtStrDateToMessage] =
        '初回送信日(From)が指定されてる時は初回送信日(To)は必須です'
    }
    if (
      !params[storageKeyList.createdAtStrDateFrom] &&
      params[storageKeyList.createdAtStrDateTo]
    ) {
      valid[storageKeyList.createdAtStrDateFromMessage] =
        '初回送信日(To)が指定されてる時は初回送信日(From)は必須です'
    }

    if (
      params[storageKeyList.exchangedAtStrDateFrom] &&
      !params[storageKeyList.exchangedAtStrDateTo]
    ) {
      valid[storageKeyList.exchangedAtStrDateToMessage] =
        '最終送信日(From)が指定されてる時は最終送信日(To)は必須です'
    }
    if (
      !params[storageKeyList.exchangedAtStrDateFrom] &&
      params[storageKeyList.exchangedAtStrDateTo]
    ) {
      valid[storageKeyList.exchangedAtStrDateFromMessage] =
        '最終送信日(To)が指定されてる時は最終送信日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const handleDeleteSearchConditions = (target, id = null) => () => {
    const params = JSON.parse(JSON.stringify(searchConditions))
    switch (target) {
      case storageKeyList.threadState: {
        const statuses = searchConditions[target]
        const trimStatuses = statuses.filter(status => status !== id)
        setSelectedStatus(trimStatuses)
        if (trimStatuses.length > 0) {
          params[target] = trimStatuses
        } else {
          delete params[target]
          setSelectedStatus([ThreadState.all.code])
        }
        break
      }
      case storageKeyList.replyState: {
        setIsReply(false)
        delete params[target]
        break
      }
      case storageKeyList.parentCategory: {
        delete params[target]
        delete params[storageKeyList.childCategory]
        break
      }
      case storageKeyList.createdAtStrDateFrom:
      case storageKeyList.createdAtStrDateTo: {
        delete params[storageKeyList.createdAtStrDateFrom]
        delete params[storageKeyList.createdAtStrDateTo]
        break
      }
      case storageKeyList.exchangedAtStrDateFrom:
      case storageKeyList.exchangedAtStrDateTo: {
        delete params[storageKeyList.exchangedAtStrDateFrom]
        delete params[storageKeyList.exchangedAtStrDateTo]
        break
      }
      case storageKeyList.assignedCompanyGroup: {
        delete params[target]
        delete params[storageKeyList.assignedCompanyUser]
        break
      }
      case storageKeyList.bulkDeliveryList: {
        delete params[target]
        setBulkDeliveryListName(null)
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params[storageKeyList.pageNumber] = 1
    isOwnerPath
      ? props.saveOwnerMessageSearchCondition(params)
      : props.saveMessageSearchCondition(params)
    setSearchConditions(params, setPageNumber(1), setOrderBy({}))
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchConditions['orderBy'] = sortParam
    searchConditions[storageKeyList.pageNumber] = 1
    setPageNumber(1)
  }

  const isContainsMessageBySearchConditions = () => {
    const prefix = isOwnerPath ? 'ownerMessage' : 'message'
    const val = Object.keys(searchConditions).find(
      x => x.match(new RegExp(`^${prefix}.+`)) && searchConditions[x] != ''
    )
    return val !== null && val !== undefined
  }

  return (
    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {searchConditions &&
            Object.keys(searchConditions).length > 0 &&
            hasSearchConditions &&
            isContainsMessageBySearchConditions() && (
              <Paper component="ul" className={classes.chips}>
                {Object.keys(searchConditions).map(key => {
                  let label = ''
                  switch (key) {
                    case storageKeyList.threadState: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != undefined
                      ) {
                        const statuses = searchConditions[key]
                        return (
                          <>
                            {statuses &&
                              statuses.length > 0 &&
                              statuses.map(status => {
                                return (
                                  <CommonChip
                                    key={status}
                                    label={
                                      fromCode(ThreadState, Number(status))
                                        .value
                                    }
                                    onDelete={handleDeleteSearchConditions(
                                      key,
                                      status
                                    )}
                                  />
                                )
                              })}
                          </>
                        )
                      }
                      break
                    }
                    case storageKeyList.replyState: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        if (searchConditions[key] == 1) {
                          label = '未返信: ON'
                        } else if (searchConditions[key] == 2) {
                          label = '返信済: ON'
                        }
                      }
                      break
                    }
                    case storageKeyList.residentName: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = isOwnerPath ? 'オーナー名: ' : '入居者名: '
                        label += searchConditions[key]
                      }
                      break
                    }
                    case storageKeyList.id: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = 'ID: ' + searchConditions[key]
                      }
                      break
                    }
                    case storageKeyList.ttyName: {
                      label = '物件名: ' + searchConditions[key]
                      break
                    }
                    case storageKeyList.parentCategory: {
                      if (
                        parentCategoryList &&
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        for (let category of parentCategoryList) {
                          if (
                            category.value === Number(searchConditions[key])
                          ) {
                            label = isOwnerPath ? 'カテゴリ: ' : '大カテゴリ: '
                            label += category.label
                            break
                          }
                        }
                      }
                      break
                    }
                    case storageKeyList.childCategory: {
                      if (
                        childCategoryList &&
                        searchConditions[key] != 0 &&
                        searchConditions[key] != '' &&
                        searchConditions[storageKeyList.parentCategory] != 0 &&
                        searchConditions[storageKeyList.parentCategory] != ''
                      ) {
                        for (let category of childCategoryList[
                          searchConditions[storageKeyList.parentCategory]
                        ]) {
                          if (
                            category.value === Number(searchConditions[key])
                          ) {
                            label = '中カテゴリ: ' + category.label
                            break
                          }
                        }
                      }
                      break
                    }
                    case storageKeyList.firstTransceiverState: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        if (searchConditions[key] == 1) {
                          label = '初回送信'
                        } else if (searchConditions[key] == 2) {
                          label = '初回受信'
                        }
                      }
                      break
                    }
                    case storageKeyList.createdAtStrDateFrom: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = '初回送信日時(from): ' + searchConditions[key]
                      }
                      break
                    }
                    case storageKeyList.createdAtStrDateTo: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = '初回送信日時(to): ' + searchConditions[key]
                      }
                      break
                    }
                    case storageKeyList.exchangedAtStrDateFrom: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = '最終送信日時(from): ' + searchConditions[key]
                      }
                      break
                    }
                    case storageKeyList.exchangedAtStrDateTo: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        label = '最終送信日時(to): ' + searchConditions[key]
                      }
                      break
                    }
                    case storageKeyList.assignedCompanyGroup: {
                      if (fetchedCompanyGroupList) {
                        for (let companyGroup of fetchedCompanyGroupList) {
                          if (
                            companyGroup.id === Number(searchConditions[key])
                          ) {
                            label = '担当グループ: ' + companyGroup.name
                            if (companyGroup.status === 3) {
                              label += '(削除済)'
                            }
                            break
                          }
                        }
                      }
                      break
                    }
                    case storageKeyList.assignedCompanyUser: {
                      if (fetchedCompanyUserList) {
                        for (let companyUser of fetchedCompanyUserList) {
                          if (
                            companyUser.company_user.id ===
                            Number(searchConditions[key])
                          ) {
                            const formattedName = getFormattedNameByStatus(
                              companyUser.company_user.name,
                              companyUser.company_user.status
                            )
                            label = '担当者: ' + formattedName
                            break
                          }
                        }
                      }
                      break
                    }
                    case storageKeyList.exchangedCompanyUser: {
                      if (companyUserList) {
                        for (let companyUser of companyUserList) {
                          if (
                            companyUser.value === Number(searchConditions[key])
                          ) {
                            label = '返信者: ' + companyUser.label
                            break
                          }
                        }
                      }
                      break
                    }
                    case storageKeyList.bulkDeliveryList: {
                      if (bulkDeliveryListName) {
                        label = '配信リスト名: ' + bulkDeliveryListName
                      }
                      break
                    }
                    case storageKeyList.bulkDeliveryListName: {
                      label = '配信リスト名: ' + searchConditions[key]
                      break
                    }
                    case storageKeyList.isArranged: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        const content =
                          searchConditions[key] == 1
                            ? '案件手配のみ表示'
                            : '案件手配を含めない'
                        label = content
                      }
                      break
                    }
                    case storageKeyList.isBulkDelivery: {
                      if (
                        searchConditions[key] != 0 &&
                        searchConditions[key] != ''
                      ) {
                        const content =
                          searchConditions[key] == 1
                            ? '一括配信メッセージのみを表示'
                            : '一括配信メッセージを含まない'
                        label = content
                      }
                      break
                    }
                    default: {
                      break
                    }
                  }
                  return (
                    <>
                      {label !== '' && (
                        <CommonChip
                          label={label}
                          onDelete={handleDeleteSearchConditions(key)}
                        />
                      )}
                    </>
                  )
                })}
              </Paper>
            )}
        </Grid>
        <Grid item xs={2} className={classes.tableTips}>
          <FormControlLabel
            className={classes.checkbox}
            control={
              <Checkbox
                name={storageKeyList.replyState}
                checked={isReply}
                onChange={handleChangeIsReply}
              />
            }
            label="未返信のみ表示"
          />
        </Grid>
        <Grid item xs={5} className={classes.tableTips}>
          <CheckButtons
            currents={selectedStatus}
            handleSelectedButton={handleSelectedButton}
            buttonsData={buttonsData}
          />
        </Grid>
        <SearchSaveAndCallButton />
        <Grid item xs={3} className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={threadList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {threadList && !isLoad ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            <List
              threadList={threadList.data}
              conditions={searchConditions}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoad} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={threadList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        content={
          <SearchFormContent
            parentCategoryList={parentCategoryList}
            childCategoryList={childCategoryList}
            companyGroupList={companyGroupList}
            companyUserList={companyUserList}
            fetchedCompanyGroupList={fetchedCompanyGroupList}
            fetchedCompanyUserList={fetchedCompanyUserList}
            companyUserMap={companyUserMap}
            bulkDeliveryListName={bulkDeliveryListName}
            searchConditions={searchConditions}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={
              isOwnerPath ? ScreenUrl.ownerMessageList : ScreenUrl.messageList
            }
            searchConditions={searchConditions}
            stateName={storageKeyList.threadState}
            setSelectedStatus={setSelectedStatus}
            setSearchConditions={setSearchConditions}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    selectDeliveryListId: state.selectDeliveryListId.id,
    messageSearchParams: state.saveSearchCondition.message,
    ownerMessageSearchParams: state.saveSearchCondition.ownerMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    },
    setSelectDeliveryListId: parameter => {
      dispatch(setSelectDeliveryListId(parameter))
    },
    saveMessageSearchCondition: parameter => {
      dispatch(saveMessageSearchCondition(parameter))
    },
    saveOwnerMessageSearchCondition: parameter => {
      dispatch(saveOwnerMessageSearchCondition(parameter))
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
