import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import List from './List'
import { PAGE_LIMIT } from 'utils/const'
import Paper from '@material-ui/core/Paper'
import TablePagination from '@material-ui/core/TablePagination'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Pagination from 'components/common/Pagination'

import { connect } from 'react-redux'
import {
  changeSearchCondition,
  clearSearchCondition,
  closeSearchModalDialog,
  closeSearchSaveModalDialog
} from 'actions/Actions'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import SearchSaveModalDialog from 'components/common/SearchSaveModalDialog'
import SearchSaveContent from 'components/common/SearchSaveContent'
import SearchSaveAndCallButton from 'components/common/SearchSaveAndCallButton'
import { SearchConditionContext } from 'context/SearchConditionContext'
import Loading from 'components/common/Loading'
import Typography from '@material-ui/core/Typography'
import { ScreenUrl } from 'utils/enum'
import CommonChip from 'components/common/CommonChip'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: 20
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  tableTips: {
    margin: 'auto'
  },
  tableWrapper: {
    maxHeight: '75vh',
    overflow: 'auto'
  },
  title: {
    height: '24px',
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search)
}

function RoomListContent(props) {
  const classes = useStyles()

  const { buildingId } = useParams()

  // let query = useQuery();
  // const pageId = query.get("pageId");

  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [building, setBuilding] = useState({})
  const [roomList, setRoomList] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [{}, dispatchSearchCondition] = React.useContext(SearchConditionContext)
  const [searchCondition, setSearchCondition] = useState(null)

  const handleChangePage = (event, newPage) => {
    setPageNumber(newPage + 1)
  }

  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }

  const fetchRoomList = async (buildingId, offset, limit) => {
    var filter = {}
    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        if (key == 'management.room.hya_room_no') {
          filter['hya_room_no'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.room.tenant_id') {
          filter['tenant_id'] = {
            contains: searchCondition[key]
          }
        }
        if (key == 'management.room.contractor_id') {
          filter['contractor_id'] = {
            contains: searchCondition[key]
          }
        }
      }
    })
    filter['building_id'] = {
      eq: buildingId
    }
    filter['orderBy'] = [
      {
        sorted: 'asc',
        column: 'hya_room_no'
      }
    ]

    // CSVDL用に検索条件保存
    dispatchSearchCondition({
      type: 'SET',
      payload: { roomSearchCondition: filter }
    })

    const result = await QueryHelper('getRoomList2', {
      filter: filter,
      offset: offset,
      limit: limit
    })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`部屋一覧を取得できませんでした。`)
    } else {
      setRoomList(result)
    }
  }

  const fetchBuilding = async id => {
    const params = {
      id: {
        eq: id
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getBuilding', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result)
    }
  }

  const searchPostData = async event => {
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    props.clearSearchCondition()
    props.changeSearchCondition(params, setPageNumber(1))
    props.closeSearchModalDialog()
    setSearchCondition(params)
  }

  useEffect(() => {
    if (Object.keys(props.conditions).length === 0) {
      fetchSearchConditions()
    }
  }, [])

  useEffect(() => {
    if (searchCondition) {
      fetchData(buildingId)
    }
  }, [pageNumber, setRoomList, searchCondition, props.conditions])

  const fetchSearchConditions = async () => {
    const result = await QueryHelper('getSearchConditionList', {
      screen: ScreenUrl.managementBuildingRoom
    })
    if (!result.error) {
      let defaultSearchCondition = {}
      for (let row of result.data) {
        if (row.isDefault) {
          defaultSearchCondition = row.condition
          break
        }
      }
      if (Object.keys(defaultSearchCondition).length > 0) {
        setSearchCondition(JSON.parse(defaultSearchCondition))
      } else {
        setSearchCondition({})
      }
    }
  }

  const handleDeleteSearchCondition = (target = null) => () => {
    const params = JSON.parse(JSON.stringify(searchCondition))
    delete params[target]
    params['pageNumber'] = 1
    setSearchCondition(params, setPageNumber(1))
  }

  const isContainsManagementRoomBySearchConditions = () => {
    const val = Object.keys(searchCondition).find(x =>
      x.match(/^management.room.+/)
    )
    return val !== null && val !== undefined
  }

  const SearchConditionChips = () => {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsManagementRoomBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'management.room.hya_room_no': {
                    label = '部屋番号: ' + searchCondition[key]
                    break
                  }
                  case 'management.room.tenant_id': {
                    label = '入居者名: ' + searchCondition[key]
                    break
                  }
                  case 'management.room.contractor_id': {
                    label = '契約者名: ' + searchCondition[key]
                    break
                  }
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  const fetchData = async buildingId => {
    if (buildingId !== undefined) {
      const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
      setIsLoading(true)
      await Promise.all([
        fetchBuilding(buildingId),
        fetchRoomList(buildingId, offset, PAGE_LIMIT)
      ])
      setIsLoading(false)
    }
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#f5f5f5'
      }}
    >
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}>
          <Typography className={classes.title}>
            {building && building.mst_tateya && building.mst_tateya.TTY_NAME}
          </Typography>
        </Grid>
        <Grid item xs={3} className={classes.tableTips}></Grid>
        <SearchSaveAndCallButton />
        <Grid item xs className={classes.tableTips}>
          <TablePagination
            rowsPerPageOptions={[]}
            component="div"
            count={roomList.paging.count}
            rowsPerPage={PAGE_LIMIT}
            page={pageNumber - 1}
            onChangePage={handleChangePage}
          />
        </Grid>
      </Grid>
      {!isLoading && roomList ? (
        <Paper className={classes.root} style={{ marginTop: 20 }}>
          <div className={classes.tableWrapper}>
            {roomList ? <List roomList={roomList.data} /> : <LinearProgress />}
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Pagination
        handlePageNumber={handleCustomChangePage}
        pageNumber={pageNumber}
        count={roomList.paging.count}
        rowsPerPage={PAGE_LIMIT}
      />
      <SearchFormModalDialog
        // title="物件の検索"
        // description="※削除すると、各所から表示されなくなります。"
        content={<SearchFormContent searchCondition={searchCondition} />}
        footer={<SearchModalDialogFooter />}
        postData={searchPostData}
      />
      <SearchSaveModalDialog
        content={
          <SearchSaveContent
            screen={ScreenUrl.managementBuildingRoom}
            searchConditions={searchCondition}
            setSearchConditions={setSearchCondition}
            closeSearchSaveModalDialog={props.closeSearchSaveModalDialog}
          />
        }
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    changeSearchCondition: parameter => {
      dispatch(changeSearchCondition(parameter))
    },
    clearSearchCondition: () => {
      dispatch(clearSearchCondition())
    },
    closeSearchSaveModalDialog: () => {
      dispatch(closeSearchSaveModalDialog())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(RoomListContent))
