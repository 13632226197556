import React, { useEffect, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'

function SearchFormContent(props) {
  const [tysList, setTysList] = useState([])
  const [prefectureList, setPrefectureList] = useState([])
  const [cityList, setCityList] = useState([
    {
      label: '都道府県を選択してください',
      value: ''
    }
  ])
  const [knrDivList, setKnrDivList] = useState([])
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState([])

  const handleChange = event => {
    if (event.target.value) {
      API.graphql(
        graphqlOperation(queries.getCityList, {
          filter: { prf_cd: { eq: event.target.value } }
        })
      )
        .then(response => {
          const errors = response.errors
          if (!errors) {
            const result = response.data.getCityList
            const l = [{ value: '', label: '' }]
            result.data.forEach(item => {
              l.push({ value: item.cty_cd, label: item.name })
            })
            setCityList(l)
          } else {
            props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
              variant: 'error'
            })
          }
        })
        .catch(error => {
          props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
            variant: 'error'
          })
        })
    } else {
      setCityList([])
    }
  }
  const fetchTysList = async () => {
    API.graphql(graphqlOperation(queries.getMSTTateyaTysList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaTysList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.tys_cd, label: item.tys_name })
          })
          setTysList(l)
        } else {
          props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('種別一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }
  const fetchPrefectureList = async () => {
    API.graphql(graphqlOperation(queries.getPrefectureList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getPrefectureList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.prf_cd, label: item.name })
          })
          setPrefectureList(l)
        } else {
          props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('都道府県一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }
  const fetchKnrDivList = async () => {
    API.graphql(graphqlOperation(queries.getMSTTateyaKNRDivList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaKNRDivList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.knr_cd, label: item.knr_name })
          })
          setKnrDivList(l)
        } else {
          props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const fetchKnrkeitaiDivList = async () => {
    API.graphql(graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {}))
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getMSTTateyaKnrkeitaiDivList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.knrkeitai_cd, label: item.knrkeitai_name })
          })
          setKnrkeitaiDivList(l)
        } else {
          props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const fetchCityList = async prfCd => {
    API.graphql(
      graphqlOperation(queries.getCityList, {
        filter: { prf_cd: { eq: prfCd } }
      })
    )
      .then(response => {
        const errors = response.errors
        if (!errors) {
          const result = response.data.getCityList
          const l = [{ value: '', label: '' }]
          result.data.forEach(item => {
            l.push({ value: item.cty_cd, label: item.name })
          })
          setCityList(l)
        } else {
          props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
            variant: 'error'
          })
        }
      })
      .catch(error => {
        props.enqueueSnackbar('市区町村一覧情報を取得できませんでした', {
          variant: 'error'
        })
      })
  }

  const fetchData = async () => {
    await Promise.all([
      fetchTysList(),
      fetchPrefectureList(),
      fetchKnrDivList(),
      fetchKnrkeitaiDivList(),
      props.searchCondition &&
      'management.building.TTY_PRF_CD' in props.searchCondition
        ? fetchCityList(props.searchCondition['management.building.TTY_PRF_CD'])
        : ''
    ])
  }

  useEffect(() => {
    fetchData()
  }, [
    setTysList,
    setPrefectureList,
    setKnrDivList,
    setKnrkeitaiDivList,
    props.searchCondition
  ])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextInput
          name="management.building.TTY_NAME"
          label="物件名(フリガナ)"
          defaultValue={
            props.searchCondition &&
            'management.building.TTY_NAME' in props.searchCondition
              ? props.searchCondition['management.building.TTY_NAME']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          name="management.building.TTY_KNR_NO"
          label="建物番号"
          defaultValue={
            props.searchCondition &&
            'management.building.TTY_KNR_NO' in props.searchCondition
              ? props.searchCondition['management.building.TTY_KNR_NO']
              : ''
          }
          fullWidth
          variant="filled"
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <Dropdown
            name="management.building.TTY_TYS_CD"
            label="種別"
            defaultValue={
              props.searchCondition &&
              'management.building.TTY_TYS_CD' in props.searchCondition
                ? props.searchCondition['management.building.TTY_TYS_CD']
                : ''
            }
            items={tysList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.building.TTY_PRF_CD"
            label="都道府県"
            defaultValue={
              props.searchCondition &&
              'management.building.TTY_PRF_CD' in props.searchCondition
                ? props.searchCondition['management.building.TTY_PRF_CD']
                : ''
            }
            onChange={handleChange}
            items={prefectureList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.building.TTY_CTY_CD"
            label="市区町村"
            defaultValue={
              props.searchCondition &&
              'management.building.TTY_CTY_CD' in props.searchCondition
                ? props.searchCondition['management.building.TTY_CTY_CD']
                : ''
            }
            items={cityList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.building.TTY_KNR_DIV"
            label="管理区分"
            defaultValue={
              props.searchCondition &&
              'management.building.TTY_KNR_DIV' in props.searchCondition
                ? props.searchCondition['management.building.TTY_KNR_DIV']
                : ''
            }
            items={knrDivList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth>
          <Dropdown
            name="management.building.TTY_KNRKEITAI_DIV"
            label="管理形態"
            defaultValue={
              props.searchCondition &&
              'management.building.TTY_KNRKEITAI_DIV' in props.searchCondition
                ? props.searchCondition['management.building.TTY_KNRKEITAI_DIV']
                : ''
            }
            items={knrkeitaiDivList}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

export default withSnackbar(SearchFormContent)
