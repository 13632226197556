import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MutationHelper, QueryHelper } from 'utils/api.utils'
import { makeStyles } from '@material-ui/core/styles'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import DetailLayout from 'components/common/DetailLayout'
import { FormControlLabel, Paper } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { formatYMD, formatYYYYMMDD } from 'utils/date.utils'
import Link from '@material-ui/core/Link'
import CustomerRemarkForm from './CustomerRemarkForm'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import AppUserFormDialog from './AppUserFormDialog'
import Checkbox from '@material-ui/core/Checkbox'
import { useRole } from 'hooks/useRole'
import MembersLabel from 'components/common/MembersLabel'

const useStyles = makeStyles(theme => ({
  body: {
    flex: 1,
    backgroundColor: '#fff'
  },
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '16%',
    height: '21px',
    color: '#2c3f58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    letterSpacing: 0,
    lineHeight: '21px'
  },
  columnValue2: {
    width: '34%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  divider: {
    width: '100%',
    height: '24px'
  },
  inputForm: {
    padding: '20px'
  },
  button: {
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

function CellWithBirthDate({ birthdate }) {
  if (birthdate) {
    const date = new Date(birthdate)
    return <>{formatYMD(date)}</>
  }
  return <></>
}

function CellWithAddress({ mstTateya }) {
  let s =
    mstTateya.TTY_PRF_NAME +
    ' ' +
    mstTateya.TTY_CTY_NAME +
    ' ' +
    mstTateya.TTY_TWN_NAME
  if (mstTateya.TTY_ADDR_01) {
    s = s + ' ' + mstTateya.TTY_ADDR_01
  }
  if (mstTateya.TTY_ADDR_02) {
    s = s + ' ' + mstTateya.TTY_ADDR_02
  }
  return s
}

function CustomerDetail({
  customer,
  contract,
  mstKokyakuKykNky,
  knrKeiyaku,
  appUser,
  fetchData
}) {
  const classes = useStyles()
  const { role3 } = useRole()
  const isRestrictedRole = role3()

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  function fetch() {
    fetchData(contract.id)
  }

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              顧客番号
            </TableCell>
            <TableCell align="left" className={classes.columnName1} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_KNR_NO}
            </TableCell>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              メールアドレス（ログインID）
            </TableCell>
            <TableCell align="left" className={classes.columnName1} colSpan="1">
              <Grid container>
                <Grid item xs={12}>
                  {appUser && appUser.username}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    className={classes.button}
                    variant="contained"
                    onClick={handleOpen}
                    disabled={isRestrictedRole}
                  >
                    ログイン情報を編集
                  </Button>
                  {open && (
                    <AppUserFormDialog
                      open={open}
                      customerId={customer.id}
                      handleClose={handleClose}
                      fetchData={fetch}
                    />
                  )}
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              氏名
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_NAME}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              フリガナ
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_NAME_KANA}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              生年月日
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_BIRTHDAY && (
                <CellWithBirthDate birthdate={mstKokyakuKykNky.KKY_BIRTHDAY} />
              )}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              携帯電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_TEL_02}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              続柄
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_NKY_ZKG_NAME}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              性別
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_SEX_DIV_NAME}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              国籍
            </TableCell>
            <TableCell align="left" className={classes.columnName1} colSpan="3">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_NATIONARITY}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              勤務先
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="3">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_WORKPLACE}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              職業
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_SKG_NAME}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              勤務先電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_WORKPLACE_TEL_01}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function BuildingDetail({ mstTateya, mstHeya, knrKeiyaku, building, room }) {
  const history = useHistory()
  const classes = useStyles()

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow aria-colspan={4}>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              物件ID
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstTateya && mstTateya.TTY_ID}
            </TableCell>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              建物番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstTateya && mstTateya.TTY_KNR_NO}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              物件名
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="3">
              {mstTateya && mstTateya.TTY_NAME}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              物件名フリガナ
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="3">
              {mstTateya && mstTateya.TTY_NAME_KANA}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              部屋番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstHeya && mstHeya.HYA_ROOM_NO && (
                <Link
                  href="javascript:void(0);"
                  onClick={() =>
                    history.push(
                      `/management/building/${building.id}/room/${room.id}`
                    )
                  }
                  className={classes.link}
                >
                  {mstHeya.HYA_ROOM_NO}
                </Link>
              )}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              郵便番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstTateya && mstTateya.TTY_ZIPCODE}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              住所
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="3">
              {mstTateya && <CellWithAddress mstTateya={mstTateya} />}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              契約ID
            </TableCell>
            <TableCell align="left" className={classes.columnName1} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_ID}
            </TableCell>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              契約状況
            </TableCell>
            <TableCell align="left" className={classes.columnName1} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_STATUS_DIV_NAME}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              契約開始日
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_STR_DATE
                ? formatYYYYMMDD(new Date(knrKeiyaku.KYK_KEIYAKU_STR_DATE))
                : null}
            </TableCell>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              契約終了日
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_END_DATE
                ? formatYYYYMMDD(new Date(knrKeiyaku.KYK_KEIYAKU_END_DATE))
                : null}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              契約日（成約日）
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_KEIYAKU_DATE
                ? formatYYYYMMDD(new Date(knrKeiyaku.KYK_KEIYAKU_DATE))
                : null}
            </TableCell>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              入居日
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_NYUKYO_DATE
                ? formatYYYYMMDD(new Date(knrKeiyaku.KYK_NYUKYO_DATE))
                : null}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              退去予告期限
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_TAIKYOYOKOKU_NUM ? (
                <>
                  {knrKeiyaku.KYK_TAIKYOYOKOKU_NUM}
                  {knrKeiyaku.KYK_TAIKYOYOKOKU_DIV_NAME}
                </>
              ) : null}
            </TableCell>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="1"
            >
              定期借家
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstTateya && (
                <>{knrKeiyaku.KYK_TEISYAKU_FLG ? <>あり</> : <>なし</>}</>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function ContractDetail({ knrKeiyakuRGetsuji }) {
  const classes = useStyles()
  if (!knrKeiyakuRGetsuji) {
    return <></>
  }
  let total = 0
  let isTax = false
  const formatter = new Intl.NumberFormat('ja-JP')
  const rows = knrKeiyakuRGetsuji.map(row => {
    let kingaku = row.KYK_GTJ_KINGAKU
    if (kingaku !== undefined && kingaku) {
      kingaku += row.KYK_GTJ_KINGAKU_TAX
      // KYK_GTJ_TAX_DIV 0:非課税 1:内税 2:対象外
      let div = row.KYK_GTJ_TAX_DIV
      if (div !== undefined && div && div == 1) {
        isTax = true
      } else {
        isTax = false
      }
    } else {
      kingaku = row.total_amount
      // tax_flg 0:内税 1:対象外
      let taxFlg = row.tax_flg
      if (taxFlg === 0) {
        isTax = true
      } else {
        isTax = false
      }
    }
    total += kingaku
    return (
      <TableRow key="KYK_GTJ">
        <TableCell align="left" className={classes.columnName2} colSpan="1">
          {row.KYK_GTJ_TKM_NAME}
        </TableCell>
        <TableCell align="left" className={classes.columnName2} colSpan="1">
          {formatter.format(kingaku)}円{isTax ? '(内税)' : ''}
        </TableCell>
        <TableCell
          align="left"
          className={classes.columnName2}
          colSpan="2"
        ></TableCell>
      </TableRow>
    )
  })

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          {rows}
          <TableRow>
            <TableCell
              align="left"
              className={classes.contentTitle}
              colSpan="2"
            >
              <Grid container>
                <Grid item>
                  <Typography
                    style={{
                      height: '12px',
                      color: '#72809D',
                      fontFamily: 'Hiragino Sans',
                      fontWeight: 300,
                      fontSize: '12px',
                      lineHeight: '18px'
                    }}
                  >
                    課税がある場合は、税金を含む金額を表記しています。
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      height: '12px',
                      color: '#72809D',
                      fontFamily: 'Hiragino Sans',
                      fontWeight: 300,
                      fontSize: '12px',
                      lineHeight: '18px'
                    }}
                  >
                    隔月の表記のものは年払い等の支払いケースも含まれます。
                  </Typography>
                </Grid>
              </Grid>
            </TableCell>
            <TableCell
              align="right"
              className={classes.columnName1}
              colSpan="2"
            >
              <Typography
                style={{
                  height: '24px',
                  color: '#2C3F58',
                  fontFamily: 'Hiragino Sans',
                  fontWeight: 600,
                  fontSize: '16px',
                  lineHeight: '24px'
                }}
              >
                合計 {formatter.format(total)}円
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function PaymentDetail({ knrKeiyaku }) {
  const classes = useStyles()

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              支払い方法
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_SEK_DIV_NAME}
              {getPaymentLimitDate(knrKeiyaku)}
            </TableCell>
            <TableCell
              align="left"
              className={classes.columnName2}
              colSpan="1"
            ></TableCell>
            <TableCell
              align="left"
              className={classes.columnName2}
              colSpan="1"
            ></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function getPaymentLimitDate(knrKeiyaku) {
  if (knrKeiyaku && knrKeiyaku.KYK_SEK_LIMIT_DATE) {
    return '（' + knrKeiyaku.KYK_SEK_LIMIT_DATE + '）'
  }
  return ''
}

function ContractorDetail({ mstKokyakuKykKys }) {
  const classes = useStyles()
  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              氏名
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_NAME}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              フリガナ
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_NAME_KANA}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_TEL_01}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              携帯電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_TEL_02}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              法人区分
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_LEGALPERSON_DIV_NAME}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function GuarantorDetail({ mstKokyakuKykKys, knrKeiyaku, pattern }) {
  const classes = useStyles()
  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              氏名
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_NAME}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              フリガナ
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_NAME_KANA}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_TEL_01}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              携帯電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_TEL_02}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              続柄
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {pattern === 1 && knrKeiyaku && knrKeiyaku.KYK_HSN_01_ZKG_NAME}
              {pattern === 2 && knrKeiyaku && knrKeiyaku.KYK_HSN_02_ZKG_NAME}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              法人区分
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykKys && mstKokyakuKykKys.KKY_LEGALPERSON_DIV_NAME}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function EmergencyCallDetail({ mstKokyakuKykNky }) {
  const classes = useStyles()

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              氏名
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_EMARGENCY_ATENA}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              フリガナ
            </TableCell>
            <TableCell
              align="left"
              className={classes.columnName2}
              colSpan="1"
            ></TableCell>
          </TableRow>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_EMARGENCY_TEL_01}
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              携帯電話番号
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {mstKokyakuKykNky && mstKokyakuKykNky.KKY_EMARGENCY_TEL_02}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function RenewALeaseDetail({ knrKeiyaku }) {
  const classes = useStyles()

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow aria-colspan={4}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              更新期間
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_KOSHIN_YR}年
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              自動更新
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && (
                <>{knrKeiyaku.KYK_KOSHIN_AUTO_FLG ? <>あり</> : <>なし</>}</>
              )}
            </TableCell>
          </TableRow>
          <TableRow aria-colspan={2}>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              更新通知
            </TableCell>
            <TableCell align="left" className={classes.columnName2} colSpan="1">
              {knrKeiyaku && knrKeiyaku.KYK_KOSHINYOKOKU_NUM}
              {knrKeiyaku && knrKeiyaku.KYK_KOSHINYOKOKU_DIV_NAME}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const LinkIotProvidersDetail = ({
  customer,
  iotProviderList,
  checkedIotProviderId,
  handleLinkIotProviderChange
}) => {
  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          {iotProviderList &&
            iotProviderList.length > 0 &&
            iotProviderList.map((row, index) => (
              <TableRow key={index}>
                <TableCell>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id={row.id}
                        name={row.name}
                        checked={
                          checkedIotProviderId != null
                            ? checkedIotProviderId == row.id
                            : customer.iot_provider_id == row.id
                        }
                        onChange={event =>
                          handleLinkIotProviderChange(event, row.id)
                        }
                      />
                    }
                    label={row.name}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const MemberFlagRow = ({ label, memberFlag, memberName }) => {
  return (
    <TableRow>
      <TableCell>{label}</TableCell>
      <TableCell>
        <Grid container alignItems="center" spacing={2}>
          {memberFlag !== null && (
            <Grid item>
              <MembersLabel memberFlag={memberFlag} />
            </Grid>
          )}
          {memberName !== null && <Grid item>{memberName}</Grid>}
        </Grid>
      </TableCell>
    </TableRow>
  )
}

const MembersFlag = ({ knrKeiyaku }) => {
  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <MemberFlagRow
            label="会員フラグ1"
            memberFlag={knrKeiyaku.KYK_MEMBER_FLG}
            memberName={knrKeiyaku.KYK_MEMBER_NAME}
          />
          <MemberFlagRow
            label="会員フラグ2"
            memberFlag={knrKeiyaku.KYK_MEMBER_FLG_2}
            memberName={knrKeiyaku.KYK_MEMBER_NAME_2}
          />
        </TableBody>
      </Table>
    </TableContainer>
  )
}

function Customer(props) {
  const classes = useStyles()
  const { id } = useParams()
  const [contract, setContract] = useState({})
  const [mstKokyakuKykNky, setMstKokyakuKykNky] = useState({})
  const [mstTateya, setMstTateya] = useState({})
  const [mstHeya, setMstHeya] = useState({})
  const [knrKeiyaku, setKnrKeiyaku] = useState({})
  const [mstKokyakuKykKys, setMstKokyakuKykKys] = useState({})
  const [mstKokyakuKykHsn1, setMstKokyakuKykHsn1] = useState({})
  const [mstKokyakuKykHsn2, setMstKokyakuKykHsn2] = useState({})
  const [customer, setCustomer] = useState({})
  const [building, setBuilding] = useState({})
  const [room, setRoom] = useState({})
  const [knrKeiyakuRGetsuji, setknrKeiyakuRGetsuji] = useState([])
  const [appUser, setAppUser] = useState({})
  const [iotProviderList, setIotProviderList] = useState({})
  const [checkedIotProviderId, setCheckedIotProviderId] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const fetchContract = async contractId => {
    const params = {
      id: {
        eq: contractId
      }
    }
    const result = await QueryHelper('getContract', { filter: params })
    if (!result.error) {
      setContract(result.contract)
      setKnrKeiyaku(result.knr_keiyaku)
      setMstKokyakuKykNky(result.mst_kokyaku_kyk_nky)
      setMstTateya(result.mst_tateya)
      setMstHeya(result.mst_heya)
      setMstKokyakuKykKys(result.mst_kokyaku_kyk_kys)
      setMstKokyakuKykHsn1(result.mst_kokyaku_kyk_hsn_01)
      setMstKokyakuKykHsn2(result.mst_kokyaku_kyk_hsn_02)
      setCustomer(result.customer)
      setknrKeiyakuRGetsuji(result.knr_keiyaku_r_getsuji)
      await Promise.all([
        fetchRoom(result.mst_heya.HYA_ID),
        fetchBuildings(result.mst_tateya.TTY_ISP_TTY_ID),
        fetchCustomer(result.contract.tenant_id),
        fetchCompanyIotProviders()
      ])
    } else {
      props.enqueueSnackbar(`Contractを取得できませんでした。`)
    }
  }

  const fetchRoom = async hyaId => {
    const params = {
      hya_id: {
        eq: hyaId
      }
    }
    const result = await QueryHelper('getRoom', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`部屋を取得できませんでした。`)
    } else {
      setRoom(result.room)
    }
  }

  const fetchBuildings = async ttyIspId => {
    const params = {
      tty_isp_tty_id: {
        eq: ttyIspId
      }
    }
    const result = await QueryHelper('getBuilding', { filter: params })
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result.building)
    }
  }

  const fetchCustomer = async customerId => {
    const params = {
      id: {
        eq: customerId
      }
    }
    const result = await QueryHelper('getCustomerList', { filter: params })
    if (result.error) {
      console.log(result.error)
      props.enqueueSnackbar('カスタマー一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      setAppUser(result.data[0].appUser)
    }
  }

  const fetchCompanyIotProviders = async () => {
    const result = await QueryHelper('getCompanyIotProviders', {})
    if (result.error) {
      console.error(result.error)
      props.enqueueSnackbar(`Iot企業情報を取得できませんでした。`)
    } else {
      setIotProviderList(result.data)
    }
  }

  const fetchData = async contractId => {
    setIsLoading(true)
    await Promise.all([fetchContract(contractId)])
    setIsLoading(false)
  }

  const handleLinkIotProviderChange = async (event, id) => {
    event.preventDefault()
    if (checkedIotProviderId != null) {
      if (checkedIotProviderId == id) {
        id = 0
      }
    } else if (customer.iot_provider_id == id) {
      id = 0
    }

    if (!window.confirm(`IoT連携設定を更新します。よろしいですか？`)) {
      return
    }
    setIsLoading(true)
    const params = { id: customer.id, iot_provider_id: id }
    const result = await MutationHelper('updateCustomer', { input: params })
    if (result.error) {
      console.error(result.error)
      props.enqueueSnackbar('更新できませんでした', {
        variant: 'error'
      })
    } else {
      setCheckedIotProviderId(id)
      props.enqueueSnackbar('更新しました', {
        variant: 'success'
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (id !== undefined) {
      fetchData(id)
    }
  }, [props])

  return (
    <>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="入居者" isLoading={isLoading}>
            <CustomerDetail
              customer={customer}
              contract={contract}
              mstKokyakuKykNky={mstKokyakuKykNky}
              knrKeiyaku={knrKeiyaku}
              appUser={appUser}
              fetchData={fetchData}
            />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="契約物件" isLoading={isLoading}>
            <BuildingDetail
              mstTateya={mstTateya}
              mstHeya={mstHeya}
              knrKeiyaku={knrKeiyaku}
              building={building}
              room={room}
            />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="契約条件" isLoading={isLoading}>
            <ContractDetail
              knrKeiyaku={knrKeiyaku}
              knrKeiyakuRGetsuji={knrKeiyakuRGetsuji}
            />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="支払情報" isLoading={isLoading}>
            <PaymentDetail knrKeiyaku={knrKeiyaku} />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="契約者" isLoading={isLoading}>
            <ContractorDetail mstKokyakuKykKys={mstKokyakuKykKys} />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="保証人1" isLoading={isLoading}>
            <GuarantorDetail
              mstKokyakuKykKys={mstKokyakuKykHsn1}
              knrKeiyaku={knrKeiyaku}
              pattern={1}
            />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="保証人2" isLoading={isLoading}>
            <GuarantorDetail
              mstKokyakuKykKys={mstKokyakuKykHsn2}
              knrKeiyaku={knrKeiyaku}
              pattern={2}
            />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="緊急連絡先" isLoading={isLoading}>
            <EmergencyCallDetail mstKokyakuKykNky={mstKokyakuKykNky} />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="更新情報" isLoading={isLoading}>
            <RenewALeaseDetail knrKeiyaku={knrKeiyaku} />
          </DetailLayout>
        </Paper>
      </div>
      {iotProviderList && iotProviderList.length > 0 && (
        <>
          <div className={classes.divider} />
          <div className={classes.body}>
            <Paper>
              <DetailLayout title="連携" isLoading={isLoading}>
                <LinkIotProvidersDetail
                  customer={customer}
                  iotProviderList={iotProviderList}
                  checkedIotProviderId={checkedIotProviderId}
                  handleLinkIotProviderChange={handleLinkIotProviderChange}
                />
              </DetailLayout>
            </Paper>
          </div>
        </>
      )}
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="会員フラグ" isLoading={isLoading}>
            <MembersFlag knrKeiyaku={knrKeiyaku} />
          </DetailLayout>
        </Paper>
      </div>
      <div className={classes.divider} />
      <div className={classes.body}>
        <Paper>
          <DetailLayout title="入居者備考" isLoading={isLoading}>
            <CustomerRemarkForm customer={customer} knrKeiyaku={knrKeiyaku} />
          </DetailLayout>
        </Paper>
      </div>
    </>
  )
}

export default withSnackbar(Customer)
