import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { Auth, Cache, Storage } from 'aws-amplify'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Grid } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Button from '@material-ui/core/Button'
import Loading from 'components/common/Loading'
import { connect } from 'react-redux'
import { deleteSearchConditions } from 'actions/Actions'

const useStyles = makeStyles(theme => ({
  '.MuiDrawer-paper': {
    maxHeight: '600px'
  },
  title: {
    flexGrow: 1,
    fontWeight: 'bold'
  },
  list: {
    width: '400px'
  },
  container: {
    maxHeight: 540,
    maxWidth: '100%'
  },
  large: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '21px',
    color: '#2c3f58'
  },
  medium: {
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '18px',
    color: '#72809d'
  },
  faceIcon: {
    float: 'left',
    width: '30px',
    height: '30px',
    marginRight: '12px'
  },
  faceIconImg: {
    width: '100%',
    height: '100%',
    borderRadius: '50%'
  },
  password: {
    color: '#2C3F58',
    fontFamily: 'Hiragino Sans',
    fontSize: '14px',
    lineHeight: '21px'
  },
  divider: {
    marginBottom: '7px'
  }
}))

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    width: '170px'
  }
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    {...props}
  />
))

function UserMenu(props) {
  const history = useHistory()
  const classes = useStyles()

  const [companyGroup, setCompanyGroup] = useState()
  const [companyUser, setCompanyUser] = useState()
  const [image, setImage] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { drawerState, toggleDrawer, anchorRef } = props

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async faqId => {
    setIsLoading(true)
    await Promise.all([fetchUserData()])
    setIsLoading(false)
  }

  const fetchUserData = async () => {
    const item = sessionStorage.getItem('totono-advertiser-session')
      ? sessionStorage.getItem('totono-advertiser-session')
      : localStorage.getItem('totono-advertiser-session')
    const data = JSON.parse(item)
    const result = await QueryHelper(
      'getCompanyUser',
      { filter: { id: { eq: data.user_id } } },
      false
    )

    if (result.error) {
      console.log(JSON.stringify(result))
      props.enqueueSnackbar('ユーザ情報が取得できませんでした', {
        variant: 'error'
      })
      return
    }
    setCompanyGroup(result.company_group)
    setCompanyUser(result.company_user)
    if (result.attachment) {
      const attachment = result.attachment
      Auth.currentCredentials().then(async creds => {
        const key = attachment.key
        console.log(key)
        Storage.get(key, {
          level: 'protected',
          identityId: `${attachment.company_id}`,
          expires: 60
        })
          .then(result => {
            console.log(result)
            const object = Object.assign({}, attachment, { body: result })
            setImage(object)
          })
          .catch(err => {
            console.log('error: ', err)
          })
      })
    }
  }

  const handleSignOut = event => {
    props.setSignin(false)
    localStorage.removeItem('totono-advertiser-session')
    sessionStorage.removeItem('totono-advertiser-session')
    props.deleteSearchConditions()
    Cache.clear()
    history.push('/authorize/signin')
  }

  return (
    <>
      {anchorRef && (
        <StyledMenu
          id="customized-menu"
          anchorEl={anchorRef.current}
          keepMounted
          open={drawerState}
          onClose={toggleDrawer}
        >
          {isLoading ? (
            <Loading isLoading={isLoading} />
          ) : (
            <>
              <MenuItem>
                <Grid direction="column">
                  <Grid item>
                    {image && image.status == 1 ? (
                      <div className={classes.faceIcon}>
                        <img
                          className={classes.faceIconImg}
                          alt={image.filename}
                          src={image.body}
                        />
                      </div>
                    ) : (
                      <div className={classes.faceIcon} />
                    )}
                  </Grid>
                  <Grid item className={classes.large}>
                    {companyUser && (
                      <span>
                        {companyUser.display_name
                          ? companyUser.display_name
                          : companyUser.name}
                      </span>
                    )}
                  </Grid>
                  <Grid item className={classes.medium}>
                    {companyGroup && <span>{companyGroup.name}</span>}
                  </Grid>
                </Grid>
              </MenuItem>
              <Divider className={classes.divider} />
              {companyUser && (
                <MenuItem
                  className={classes.password}
                  onClick={() =>
                    history.push(`/setting/company-user/edit/${companyUser.id}`)
                  }
                >
                  <Grid direction="column">
                    <Grid item>パスワード変更</Grid>
                  </Grid>
                </MenuItem>
              )}
              <Divider className={classes.divider} />
              <MenuItem>
                <Grid item>
                  <Button onClick={handleSignOut} variant="outlined">
                    ログアウト
                  </Button>
                </Grid>
              </MenuItem>
            </>
          )}
        </StyledMenu>
      )}
    </>
  )
}

const mapStateToProps = () => {}

const mapDispatchToProps = dispatch => {
  return {
    deleteSearchConditions: () => {
      dispatch(deleteSearchConditions())
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(UserMenu))
