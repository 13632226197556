import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import { QueryHelper } from 'utils/api.utils'
import { withSnackbar } from 'notistack'
import API, { graphqlOperation } from '@aws-amplify/api'
import * as queries from 'graphql/queries'
import { HYA_STATUS_LIST, HYA_HYA_SYSTEM_DIV_LIST } from 'utils/const'
import { getKnrDiv } from 'utils/building.utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

const fullFloor = mst_heya => {
  // return mst_heya.HYA_FLS_NUM + " " + mst_heya.HYA_FLS_CD;
  return mst_heya.HYA_FLS_NAME + mst_heya.HYA_FLS_NUM + '階'
}

const fullLayout = mst_heya => {
  // return mst_heya.HYA_MDR_NUM + " " + mst_heya.HYA_MDR_CD;
  return (mst_heya.HYA_MDR_NUM || '') + ' ' + mst_heya.HYA_MDR_NAME
}

function getStatus(status) {
  const found = HYA_STATUS_LIST.find(element => element.value == status)
  return found ? found.label : ''
}

function getHyaSystemDiv(hyaSystemDiv) {
  if (!hyaSystemDiv) {
    return ''
  }
  const found = HYA_HYA_SYSTEM_DIV_LIST.find(
    element => element.value == hyaSystemDiv
  )
  return found ? found.label : ''
}

function Detail(props) {
  const classes = useStyles()

  const { room } = props

  const [building, setBuilding] = useState({})
  const [knrDivList, setKnrDivList] = useState({})
  const [knrkeitaiDivList, setKnrkeitaiDivList] = useState({})

  const fetchBuilding = async buildingId => {
    const params = {
      id: {
        eq: buildingId
      }
    }
    // console.log(JSON.stringify(params));
    const result = await QueryHelper('getBuilding', { filter: params })
    // console.log(JSON.stringify(result));
    if (result.error) {
      props.enqueueSnackbar(`建物を取得できませんでした。`)
    } else {
      setBuilding(result)
      await Promise.all([
        fetchMstTateyaKnrDivList(),
        fetchMstTateyaKnrkeitaiDivList()
      ])
    }
  }

  const fetchMstTateyaKnrDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKNRDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理区分一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKNRDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knr_cd] = item.knr_name
      })
      setKnrDivList(l)
    }
  }

  const fetchMstTateyaKnrkeitaiDivList = async () => {
    const response = await API.graphql(
      graphqlOperation(queries.getMSTTateyaKnrkeitaiDivList, {})
    )
    if (response.errors) {
      props.enqueueSnackbar('管理形態一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const result = response.data.getMSTTateyaKnrkeitaiDivList
      const l = {}
      result.data.forEach(item => {
        l[item.knrkeitai_cd] = item.knrkeitai_name
      })
      setKnrkeitaiDivList(l)
    }
  }

  useEffect(() => {
    if ('room' in room && room.room) {
      fetchBuilding(room.room.building_id)
    }
  }, [room, setBuilding])

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.columnName1}>建物名</TableCell>
            <TableCell className={classes.columnValue1} colSpan="3">
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_NAME
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName1}>
              建物名フリガナ
            </TableCell>
            <TableCell className={classes.columnValue1} colSpan="3">
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_NAME_KANA
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>部屋番号</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? room.mst_heya.HYA_ROOM_NO
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>システム区分</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? getHyaSystemDiv(room.mst_heya.HYA_SYSTEM_DIV)
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>所属階</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? fullFloor(room.mst_heya)
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>間取り</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? fullLayout(room.mst_heya)
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>面積</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? room.mst_heya.HYA_SENYU_MENSEKI + '㎡'
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>方位</TableCell>
            {/* <TableCell className={classes.columnValue2}>{("mst_heya" in room && room.mst_heya) ? room.mst_heya.HYA_DCN_CD : ""}</TableCell> */}
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? room.mst_heya.HYA_DCN_NAME
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>管理区分</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? getKnrDiv(building.mst_tateya, knrDivList)
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>管理パターン</TableCell>
            <TableCell className={classes.columnValue2}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>種別</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? room.mst_heya.HYA_TYS_NAME
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>空室区分</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_heya' in room && room.mst_heya
                ? getStatus(room.mst_heya.HYA_STATUS_DIV)
                : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default withSnackbar(Detail)
