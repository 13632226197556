import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  fullAddress,
  fullFloor,
  formatDate,
  parseDate,
  getKnrDiv,
  getKnrkeitaiDiv
} from 'utils/building.utils'

const useStyles = makeStyles(theme => ({
  columnName: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: theme.palette.c_thirdly.main,
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: theme.palette.c_thirdly.main,
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

const BuildingDetail = props => {
  const classes = useStyles()
  const { building, knrDivList, knrkeitaiDivList } = props

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        {building && building.mst_tateya && (
          <TableBody>
            <TableRow>
              <TableCell className={classes.columnName}>建物番号</TableCell>
              <TableCell className={classes.columnValue} colSpan="3">
                {building.mst_tateya.TTY_KNR_NO}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>建物名</TableCell>
              <TableCell className={classes.columnValue} colSpan="3">
                {building.mst_tateya.TTY_NAME}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>
                建物名フリガナ
              </TableCell>
              <TableCell className={classes.columnValue} colSpan="3">
                {building.mst_tateya.TTY_NAME_KANA}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>住所</TableCell>
              <TableCell className={classes.columnValue} colSpan="3">
                {fullAddress(building.mst_tateya)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>建物種別</TableCell>
              <TableCell className={classes.columnValue}>
                {building.mst_tateya.TTY_TYS_NAME}
              </TableCell>
              <TableCell className={classes.columnName}>構造</TableCell>
              <TableCell className={classes.columnValue}>
                {building.mst_tateya.TTY_TYK_NAME}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>築年月</TableCell>
              <TableCell className={classes.columnValue}>
                {formatDate(
                  parseDate(building.mst_tateya.TTY_COMPLETION_DATE),
                  'yyyy/MM'
                )}
              </TableCell>
              <TableCell className={classes.columnName}>階数</TableCell>
              <TableCell className={classes.columnValue}>
                {fullFloor(building.mst_tateya)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>建物種別詳細</TableCell>
              <TableCell className={classes.columnValue}>
                {building.mst_tateya.TTY_STS_NAME}
              </TableCell>
              <TableCell className={classes.columnName}>管理区分</TableCell>
              <TableCell className={classes.columnValue}>
                {getKnrDiv(building.mst_tateya, knrDivList)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>管理形態</TableCell>
              <TableCell className={classes.columnValue}>
                {getKnrkeitaiDiv(building.mst_tateya, knrkeitaiDivList)}
              </TableCell>
              <TableCell className={classes.columnName}>管理パターン</TableCell>
              <TableCell className={classes.columnValue}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell className={classes.columnName}>総戸数</TableCell>
              <TableCell className={classes.columnValue} colSpan="3">
                {building.mst_tateya.TTY_TOTAL_COUNT}
              </TableCell>
            </TableRow>
          </TableBody>
        )}
      </Table>
    </TableContainer>
  )
}

export default BuildingDetail
