import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableRow from '@material-ui/core/TableRow'
import {
  fullAddress,
  fullFloor,
  formatDate,
  parseDate,
  getKnrDiv,
  getKnrkeitaiDiv
} from 'utils/building.utils'

const useStyles = makeStyles(theme => ({
  root: {
    height: '24px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 600,
    color: '#2c3f58',
    fontSize: '16px',
    lineHeight: '24px'
  },
  columnName1: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue1: {
    width: '82%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnName2: {
    width: '18%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  },
  columnValue2: {
    width: '32%',
    height: '21px',
    fontFamily: 'Hiragino Sans',
    fontWeight: 300,
    color: '#2c3f58',
    fontSize: '14px',
    lineHeight: '21px'
  }
}))

function BuildingDetail(props) {
  const classes = useStyles()

  const { building, knrDivList, knrkeitaiDivList } = props

  return (
    <TableContainer component="Paper">
      <Table aria-label="spanning table">
        <TableBody>
          <TableRow>
            <TableCell className={classes.columnName1}>建物番号</TableCell>
            <TableCell className={classes.columnValue1} colSpan="3">
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_KNR_NO
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName1}>建物名</TableCell>
            <TableCell className={classes.columnValue1} colSpan="3">
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_NAME
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName1}>
              建物名フリガナ
            </TableCell>
            <TableCell className={classes.columnValue1} colSpan="3">
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_NAME_KANA
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName1}>住所</TableCell>
            <TableCell className={classes.columnValue1} colSpan="3">
              {'mst_tateya' in building && building.mst_tateya
                ? fullAddress(building.mst_tateya)
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>建物種別</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_TYS_NAME
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>構造</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_TYK_NAME
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>築年月</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? formatDate(
                    parseDate(building.mst_tateya.TTY_COMPLETION_DATE),
                    'yyyy/MM'
                  )
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>階数</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? fullFloor(building.mst_tateya)
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>建物種別詳細</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_STS_NAME
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>管理区分</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? getKnrDiv(building.mst_tateya, knrDivList)
                : ''}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>管理形態</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? getKnrkeitaiDiv(building.mst_tateya, knrkeitaiDivList)
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}>管理パターン</TableCell>
            <TableCell className={classes.columnValue2}></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.columnName2}>総戸数</TableCell>
            <TableCell className={classes.columnValue2}>
              {'mst_tateya' in building && building.mst_tateya
                ? building.mst_tateya.TTY_TOTAL_COUNT
                : ''}
            </TableCell>
            <TableCell className={classes.columnName2}></TableCell>
            <TableCell className={classes.columnValue2}></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BuildingDetail
