import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import { withSnackbar } from 'notistack'
import TextInput from 'components/common/Form/TextInput'
import Dropdown from 'components/common/Form/Dropdown'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import localeJa from 'date-fns/locale/ja'
import { BulletinBoardState, BulletinBoardType } from 'utils/enum'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import { getFormattedNameByStatus } from 'utils/statusFormattingUtils'

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  dateMargin: {
    '& > .MuiFormControl-marginNormal ': {
      marginTop: '2px',
      marginBottom: '2px'
    }
  }
}))

function SearchFormContent(props) {
  const classes = useStyles()
  const {
    companyUserList,
    fetchedCompanyUserList,
    buildingList,
    searchConditions,
    searchConditionErrors
  } = props

  const [deactivatedCompanyUser, setDeactivatedCompanyUser] = useState(null)

  const bulletinBoardStateList = [
    { value: 0, label: '' },
    {
      value: BulletinBoardState.draft.code,
      label: BulletinBoardState.draft.value
    },
    {
      value: BulletinBoardState.reservation.code,
      label: BulletinBoardState.reservation.value
    },
    {
      value: BulletinBoardState.posting.code,
      label: BulletinBoardState.posting.value
    },
    {
      value: BulletinBoardState.complete.code,
      label: BulletinBoardState.complete.value
    }
  ]

  const bulletinBoardTypeList = [
    { value: 0, label: '' },
    {
      value: BulletinBoardType.notify.code,
      label: BulletinBoardType.notify.value
    },
    {
      value: BulletinBoardType.facility.code,
      label: BulletinBoardType.facility.value
    },
    {
      value: BulletinBoardType.resident.code,
      label: BulletinBoardType.resident.value
    },
    {
      value: BulletinBoardType.contract.code,
      label: BulletinBoardType.contract.value
    },
    {
      value: BulletinBoardType.campaign.code,
      label: BulletinBoardType.campaign.value
    }
  ]

  const [selectedState, setSelectedThreadState] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.state' in searchConditions &&
      searchConditions['bulletinBoard.state'] != ''
      ? JSON.parse(searchConditions['bulletinBoard.state'])
      : 0
  )
  const [isImportant, setIsImportant] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.is_important' in searchConditions &&
      searchConditions['bulletinBoard.is_important']
      ? true
      : false
  )
  const [title, setTitle] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.title' in searchConditions
      ? searchConditions['bulletinBoard.title']
      : ''
  )
  const [selectedType, setSelectedType] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.type' in searchConditions &&
      searchConditions['bulletinBoard.type'] != ''
      ? JSON.parse(searchConditions['bulletinBoard.type'])
      : 0
  )

  const [displayStartAtStrDateFrom, setDisplayStartAtStrDateFrom] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.display_start_at_str_date_from' in searchConditions &&
      searchConditions['bulletinBoard.display_start_at_str_date_from'] != ''
      ? searchConditions['bulletinBoard.display_start_at_str_date_from']
      : null
  )
  const [displayStartAtStrDateTo, setDisplayStartAtStrDateTo] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.display_start_at_str_date_to' in searchConditions &&
      searchConditions['bulletinBoard.display_start_at_str_date_to'] != ''
      ? searchConditions['bulletinBoard.display_start_at_str_date_to']
      : null
  )

  const [displayEndAtStrDateFrom, setDisplayEndAtStrDateFrom] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.display_end_at_str_date_from' in searchConditions &&
      searchConditions['bulletinBoard.display_end_at_str_date_from'] != ''
      ? searchConditions['bulletinBoard.display_end_at_str_date_from']
      : null
  )
  const [displayEndAtStrDateTo, setDisplayEndAtStrDateTo] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.display_end_at_str_date_to' in searchConditions &&
      searchConditions['bulletinBoard.display_end_at_str_date_to'] != ''
      ? searchConditions['bulletinBoard.display_end_at_str_date_to']
      : null
  )

  const [initialSelectedBuilding] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.building_id' in searchConditions &&
      searchConditions['bulletinBoard.building_id'] != ''
      ? JSON.parse(searchConditions['bulletinBoard.building_id'])
      : []
  )
  const [selectedBuilding, setSelectedBuilding] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.building_id' in searchConditions
      ? searchConditions['bulletinBoard.building_id']
      : []
  )

  const [updatedAtStrDateFrom, setUpdatedAtStrDateFrom] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.updated_at_str_date_from' in searchConditions &&
      searchConditions['bulletinBoard.updated_at_str_date_from'] != ''
      ? searchConditions['bulletinBoard.updated_at_str_date_from']
      : null
  )
  const [updatedAtStrDateTo, setUpdatedAtStrDateTo] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.updated_at_str_date_to' in searchConditions &&
      searchConditions['bulletinBoard.updated_at_str_date_to'] != ''
      ? searchConditions['bulletinBoard.updated_at_str_date_to']
      : null
  )

  const [selectedUpdatedCompanyUser, setSelectedUpdatedCompanyUser] = useState(
    Object.keys(searchConditions).length &&
      'bulletinBoard.updated_company_user' in searchConditions &&
      searchConditions['bulletinBoard.updated_company_user'] != ''
      ? JSON.parse(searchConditions['bulletinBoard.updated_company_user'])
      : 0
  )

  const handleChangeState = event => {
    setSelectedThreadState(JSON.stringify(event.target.value))
  }

  const handleChangeIsImportant = event => {
    setIsImportant(event.target.checked)
  }

  const handleChangeTitle = event => {
    setTitle(JSON.stringify(event.target.value))
  }

  const handleChangeType = event => {
    setSelectedType(JSON.stringify(event.target.value))
  }

  const handleChangeDisplayStartAtStrDateFrom = date => {
    if (date == null) {
      setDisplayStartAtStrDateFrom(null)
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setDisplayStartAtStrDateFrom(date)
  }
  const handleChangeDisplayStartAtStrDateTo = date => {
    if (date == null) {
      setDisplayStartAtStrDateTo(null)
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setDisplayStartAtStrDateTo(date)
  }

  const handleChangeDisplayEndAtStrDateFrom = date => {
    if (date == null) {
      setDisplayEndAtStrDateFrom(null)
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setDisplayEndAtStrDateFrom(date)
  }
  const handleChangeDisplayEndAtStrDateTo = date => {
    if (date == null) {
      setDisplayEndAtStrDateTo(null)
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setDisplayEndAtStrDateTo(date)
  }

  const handleChangeBuilding = (event, value, reason) => {
    setSelectedBuilding(JSON.stringify(value))
  }

  const handleChangeUpdatedAtStrDateFrom = date => {
    if (date == null) {
      setUpdatedAtStrDateFrom(null)
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setUpdatedAtStrDateFrom(date)
  }
  const handleChangeUpdatedAtStrDateTo = date => {
    if (date == null) {
      setUpdatedAtStrDateTo(null)
      return
    }
    date.setHours(0)
    date.setMinutes(0)
    date.setSeconds(0)
    date.setMilliseconds(0)
    setUpdatedAtStrDateTo(date)
  }

  const handleChangeSelectedUpdatedCompanyUser = event => {
    setSelectedUpdatedCompanyUser(JSON.stringify(event.target.value))
    setDeactivatedCompanyUser(null)
  }

  useEffect(() => {
    if (fetchedCompanyUserList) {
      const assignedUser = fetchedCompanyUserList.find(
        item =>
          item.company_user.status !== 1 &&
          selectedUpdatedCompanyUser === item.company_user.id
      )
      setDeactivatedCompanyUser(
        assignedUser &&
          getFormattedNameByStatus(
            assignedUser.company_user.name,
            assignedUser.company_user.status
          )
      )
    }
  }, [fetchedCompanyUserList])

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name="bulletinBoard.state"
            label={'状態'}
            onChange={handleChangeState}
            defaultValue={selectedState}
            items={bulletinBoardStateList}
          />
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControlLabel
          className={classes.important}
          control={
            <Switch
              className={classes.importantSwitch}
              checked={isImportant}
              onChange={handleChangeIsImportant}
              name="bulletinBoard.is_important"
            />
          }
          label="重要"
          labelPlacement="start"
          value={isImportant}
        />
      </Grid>

      <Grid item xs={12}>
        <TextInput
          name="bulletinBoard.title"
          label="タイトル"
          defaultValue={
            'bulletinBoard.title' in searchConditions
              ? searchConditions['bulletinBoard.title']
              : ''
          }
          value={title}
          fullWidth
          variant="filled"
          onChange={handleChangeTitle}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name="bulletinBoard.type"
            label={'種別'}
            onChange={handleChangeType}
            defaultValue={selectedType}
            items={bulletinBoardTypeList}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulletinBoard.display_start_at_str_date_from"
            name="bulletinBoard.display_start_at_str_date_from"
            label="掲載期間開始日(From)"
            value={displayStartAtStrDateFrom}
            onChange={handleChangeDisplayStartAtStrDateFrom}
            error={
              searchConditionErrors[
                'bulletinBoard.display_start_at_str_date_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'bulletinBoard.display_start_at_str_date_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulletinBoard.display_start_at_str_date_to"
            name="bulletinBoard.display_start_at_str_date_to"
            label="掲載期間開始日(To)"
            value={displayStartAtStrDateTo}
            onChange={handleChangeDisplayStartAtStrDateTo}
            error={
              searchConditionErrors[
                'bulletinBoard.display_start_at_str_date_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'bulletinBoard.display_start_at_str_date_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulletinBoard.display_end_at_str_date_from"
            name="bulletinBoard.display_end_at_str_date_from"
            label="掲載期間終了日(From)"
            value={displayEndAtStrDateFrom}
            onChange={handleChangeDisplayEndAtStrDateFrom}
            error={
              searchConditionErrors[
                'bulletinBoard.display_end_at_str_date_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'bulletinBoard.display_end_at_str_date_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulletinBoard.display_end_at_str_date_to"
            name="bulletinBoard.display_end_at_str_date_to"
            label="掲載期間終了日(To)"
            value={displayEndAtStrDateTo}
            onChange={handleChangeDisplayEndAtStrDateTo}
            error={
              searchConditionErrors[
                'bulletinBoard.display_end_at_str_date_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'bulletinBoard.display_end_at_str_date_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12}>
        <Grid item xs={12}>
          <Autocomplete
            multiple
            options={buildingList}
            getOptionLabel={option => option.TTY_NAME}
            defaultValue={initialSelectedBuilding}
            filterSelectedOptions
            filterOptions={(options, state) => {
              return options.filter(
                option =>
                  option.TTY_NAME.indexOf(state.inputValue) !== -1 ||
                  option.TTY_NAME_KANA.indexOf(state.inputValue) !== -1
              )
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="filled"
                label="配信先"
                autoComplete="off"
              />
            )}
            onChange={handleChangeBuilding}
          />
          <input
            type="hidden"
            name="bulletinBoard.building_id"
            value={selectedBuilding}
          />
        </Grid>
      </Grid>

      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulletinBoard.updated_at_str_date_from"
            name="bulletinBoard.updated_at_str_date_from"
            label="更新日(From)"
            value={updatedAtStrDateFrom}
            onChange={handleChangeUpdatedAtStrDateFrom}
            error={
              searchConditionErrors[
                'bulletinBoard.updated_at_str_date_from.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'bulletinBoard.updated_at_str_date_from.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={6} className={classes.dateMargin}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeJa}>
          <KeyboardDatePicker
            disableToolbar
            inputVariant="filled"
            format="yyyy-MM-dd"
            margin="normal"
            id="bulletinBoard.updated_at_str_date_to"
            name="bulletinBoard.updated_at_str_date_to"
            label="更新日(To)"
            value={updatedAtStrDateTo}
            onChange={handleChangeUpdatedAtStrDateTo}
            error={
              searchConditionErrors[
                'bulletinBoard.updated_at_str_date_to.message'
              ]
            }
            helperText={
              searchConditionErrors[
                'bulletinBoard.updated_at_str_date_to.message'
              ]
            }
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      <Grid item xs={12}>
        <FormControl
          required
          variant="outlined"
          className={classes.formControl}
        >
          <Dropdown
            name="bulletinBoard.updated_company_user"
            label={'更新者'}
            onChange={handleChangeSelectedUpdatedCompanyUser}
            defaultValue={selectedUpdatedCompanyUser}
            items={companyUserList}
            deactivatedItem={deactivatedCompanyUser}
          />
        </FormControl>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = state => {
  return {
    conditions: state.searchCondition
  }
}

const mapDispatchToProps = dispatch => {
  return {}
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(SearchFormContent))
