import React, { useState, useEffect } from 'react'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import FormHelperText from '@material-ui/core/FormHelperText'
import List from './List'
import SearchFormModalDialog from './SearchFormModalDialog'
import SearchFormContent from './SearchFormContent'
import SearchModalDialogFooter from './SearchModalDialogFooter'
import Pagination from 'components/common/Pagination'
import { PAGE_LIMIT } from 'utils/const'
import { QueryHelper } from 'utils/api.utils'
import Loading from 'components/common/Loading'
import { AppUsage } from 'utils/enum'
import CommonChip from 'components/common/CommonChip'
import {
  closeSearchModalDialog,
  handleClear,
  setSelectContractList
} from 'actions/Actions'
import { connect } from 'react-redux'
import { withSnackbar } from 'notistack'
import { Auth, Storage } from 'aws-amplify'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  tableWrapper: {
    maxHeight: '65vh',
    overflow: 'auto'
  },
  tableTips: {
    textAlign: 'right'
  },
  button: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    },
    marginTop: '20px'
  },
  message: {
    marginTop: '20px',
    marginLeft: '10px',
    color: '#72809D',
    fontSize: '12px'
  },
  nextButton: {
    color: theme.palette.c_white.main,
    backgroundColor: theme.palette.c_blue.main,
    '&:hover': {
      backgroundColor: theme.palette.c_blue.dark
    }
  },
  chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0
  },
  csvButton: {
    marginBottom: '15px',
    color: theme.palette.c_purple.contrastText,
    backgroundColor: theme.palette.c_purple.main,
    '&:hover': {
      backgroundColor: theme.palette.c_purple.dark
    }
  }
}))

const initialState = {
  data: [],
  paging: { count: 0, rowsPerPage: PAGE_LIMIT, page: 1 }
}

function ListContent(props) {
  const {
    enqueueSnackbar,
    closeSearchModalDialog,
    selectContractList,
    setSelectContractList
  } = props
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { pageId } = useParams()
  const [pageNumber, setPageNumber] = useState(pageId ? pageId : 1)
  const [searchCondition, setSearchCondition] = useState(
    location.state !== undefined ? location.state : {}
  )
  const [searchConditionErrors, setSearchConditionErrors] = useState({})
  const [contractList, setContractList] = useState(initialState)
  const [isLoading, setIsLoading] = useState(false)
  const [orderBy, setOrderBy] = useState(
    location.state !== undefined && location.state['orderBy']
      ? location.state['orderBy']
      : {}
  )
  const handleCustomChangePage = newPage => {
    setPageNumber(newPage)
  }

  const [totalNumber, setTotalNumber] = useState()
  const [checkedIdList, setCheckedIdList] = useState(new Set())
  // TODO: checkedIdListをselectContractListに置き換える（このファイルはRedux管理にするか検討する）
  const [controlCheckedIdList, setControlCheckedIdList] = useState(new Set())
  const [allTransmittableIdList, setAllTransmittableIdList] = useState(
    new Set()
  )
  const [transmittableIdList, setTransmittableIdList] = useState([])
  const [errorMessage, setErrorMessage] = useState('')

  const csvDownLoad = async () => {
    const finalIdArray = Array.from(checkedIdList)

    let params = {
      kyk_id_list: {
        eq: finalIdArray
      }
    }

    const csvData = await getCsv(0, null, params)
    if (csvData.error) {
      enqueueSnackbar('CSVのDLに失敗しました', {
        variant: 'error'
      })
      return
    }
    await downloadFile(csvData)
  }

  async function getCsv(offset, limit, params) {
    let result = await QueryHelper(
      'getContractCsv2',
      {
        filter: params,
        offset: offset,
        limit: limit
      },
      offset === 0
    )
    if (result.error) {
      enqueueSnackbar('入居者情報を取得できませんでした', {
        variant: 'error'
      })
      return result
    } else {
      return result
    }
  }

  async function downloadFile(csvData) {
    await Auth.currentCredentials().then(async creds => {
      const key = csvData.key
      Storage.get(key, {
        level: 'protected',
        identityId: csvData.companyId,
        expires: 60
      })
        .then(result => {
          const link = document.createElement('a')
          link.href = result
          link.click()
        })
        .catch(error => {
          console.log('error: ', error)
        })
    })
  }

  async function fetchContractList({ offset, limit }) {
    var filter = {}
    var kyk_keiyaku_date = []
    var registeredAtToArr = []
    var lastLoginAtArr = []

    Object.keys(searchCondition).forEach(function(key) {
      if (searchCondition[key]) {
        switch (key) {
          case 'message.bulk.delivery.search.tenant_id': {
            filter['tenant_id'] = {
              contains: searchCondition[key]
            }
            break
          }
          case 'message.bulk.delivery.search.kky_id': {
            filter['kky_id'] = {
              eq: Number(searchCondition[key])
            }
            break
          }
          case 'message.bulk.delivery.search.kky_knr_no': {
            filter['kky_knr_no'] = {
              eq: searchCondition[key]
            }
            break
          }
          case 'message.bulk.delivery.search.contractor_id': {
            filter['contractor_id'] = {
              contains: searchCondition[key]
            }
            break
          }
          case 'message.bulk.delivery.search.TTY_NAME': {
            filter['TTY_NAME'] = {
              contains: searchCondition[key]
            }
            break
          }
          case 'message.bulk.delivery.search.kky_tel_02': {
            filter['kky_tel_02'] = {
              eq: searchCondition[key]
            }
            break
          }

          case 'message.bulk.delivery.search.kyk_keiyaku_date_from': {
            if (
              'message.bulk.delivery.search.kyk_keiyaku_date_to' in
              searchCondition
            ) {
              kyk_keiyaku_date.push(searchCondition[key])
            } else {
              enqueueSnackbar('契約日Toを指定してください', {
                variant: 'error'
              })
              return
            }
            break
          }
          case 'message.bulk.delivery.search.kyk_keiyaku_date_to': {
            if (
              'message.bulk.delivery.search.kyk_keiyaku_date_from' in
              searchCondition
            ) {
              kyk_keiyaku_date.push(searchCondition[key])
            } else {
              enqueueSnackbar('契約日Fromを指定してください', {
                variant: 'error'
              })
              return
            }
            break
          }
          case 'message.bulk.delivery.search.registered_at_from': {
            if (
              'message.bulk.delivery.search.registered_at_to' in searchCondition
            ) {
              registeredAtToArr.push(searchCondition[key])
            } else {
              enqueueSnackbar('アプリ利用開始日Toを指定してください', {
                variant: 'error'
              })
              return
            }
            break
          }
          case 'message.bulk.delivery.search.registered_at_to': {
            if (
              'message.bulk.delivery.search.registered_at_from' in
              searchCondition
            ) {
              registeredAtToArr.push(searchCondition[key])
            } else {
              enqueueSnackbar('アプリ利用開始日Fromを指定してください', {
                variant: 'error'
              })
              return
            }
            break
          }
          case 'message.bulk.delivery.search.last_login_at_from': {
            if (
              'message.bulk.delivery.search.last_login_at_to' in searchCondition
            ) {
              lastLoginAtArr.push(searchCondition[key])
            } else {
              enqueueSnackbar('最終ログイン日Toを指定してください', {
                variant: 'error'
              })
              return
            }
            break
          }
          case 'message.bulk.delivery.search.last_login_at_to': {
            if (
              'message.bulk.delivery.search.last_login_at_from' in
              searchCondition
            ) {
              lastLoginAtArr.push(searchCondition[key])
            } else {
              enqueueSnackbar('最終ログイン日Fromを指定してください', {
                variant: 'error'
              })
              return
            }
            break
          }
          case 'message.bulk.delivery.search.is_usage': {
            if (searchCondition[key] == 1) {
              filter['app_user_id'] = {
                isNull: 0
              }
              filter['is_management'] = {
                eq: true
              }
              filter['is_usage'] = {
                eq: true
              }
            } else if (searchCondition[key] == 2) {
              filter['is_use_app'] = {
                eq: true
              }
            } else {
              filter['is_use_app'] = {
                eq: false
              }
            }
            break
          }
          case 'message.bulk.delivery.search.kyk_status_div': {
            filter['kyk_status_div'] = {
              eq: [1, 2, 3, 4, 5]
            }
            break
          }
          default:
        }
      }
    })

    if (kyk_keiyaku_date.length > 0) {
      filter['kyk_keiyaku_date'] = {
        between: kyk_keiyaku_date
      }
    }
    if (registeredAtToArr.length > 0) {
      filter['registered_at'] = {
        between: registeredAtToArr
      }
    }
    if (lastLoginAtArr.length > 0) {
      filter['last_login_at'] = {
        between: lastLoginAtArr
      }
    }
    if (!filter['kyk_status_div']) {
      filter['kyk_status_div'] = {
        eq: [1, 2, 3]
      }
    }
    if (orderBy && Object.keys(orderBy).length > 0) {
      filter.orderBy = [orderBy]
    }

    let result = await QueryHelper(
      'getContractList2',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      enqueueSnackbar('入居者一覧情報を取得できませんでした', {
        variant: 'error'
      })
    } else {
      const ids = []
      let residentList = result.data
      // 全データの件数を管理
      setTotalNumber(result.paging.count)
      setContractList(result)
      // 15件分のデータをフィルタリング
      residentList = checkFilterData(residentList)
      residentList.forEach(item => ids.push(item.contract.kyk_id))
      setTransmittableIdList(ids)
      if (selectContractList && selectContractList.selectContractList) {
        setCheckedIdList(selectContractList.selectContractList)
      }
    }
  }

  const checkFilterData = data => {
    let filterData = []
    filterData = data.filter(
      // 無効をフィルタリング
      row => row.customer.is_usage == true && row.building.is_management == true
    )
    return (filterData = filterData.filter(
      // 状態のフィルタリング
      row =>
        row.contract.kyk_status_div == 1 ||
        row.contract.kyk_status_div == 2 ||
        row.contract.kyk_status_div == 3
    ))
  }

  const searchPostData = async event => {
    setCheckedIdList(new Set())
    event.preventDefault()
    const formData = new FormData(event.target)
    let params = {}
    for (let entry of formData.entries()) {
      if (entry[1] != 0 && entry[1] != '') {
        params[entry[0]] = entry[1]
      }
    }
    if (validateSearchCondition(params)) {
      return
    }
    closeSearchModalDialog()
    params['pageNumber'] = 1
    setSearchCondition(params, setPageNumber(1), setOrderBy({}))
  }

  function validateSearchCondition(params) {
    const valid = {}

    if (
      params['message.bulk.delivery.search.kyk_keiyaku_date_from'] &&
      !params['message.bulk.delivery.search.kyk_keiyaku_date_to']
    ) {
      valid['message.bulk.delivery.search.kyk_keiyaku_date_to.message'] =
        '契約日(From)が指定されてる時は契約日(To)は必須です'
    }
    if (
      !params['message.bulk.delivery.search.kyk_keiyaku_date_from'] &&
      params['message.bulk.delivery.search.kyk_keiyaku_date_to']
    ) {
      valid['message.bulk.delivery.search.kyk_keiyaku_date_from.message'] =
        '契約日(To)が指定されてる時は契約日(From)は必須です'
    }
    if (
      params['message.bulk.delivery.search.registered_at_from'] &&
      !params['message.bulk.delivery.search.registered_at_to']
    ) {
      valid['message.bulk.delivery.search.registered_at_to.message'] =
        'アプリ利用開始日(From)が指定されてる時はアプリ利用開始日(To)は必須です'
    }
    if (
      !params['message.bulk.delivery.search.registered_at_from'] &&
      params['message.bulk.delivery.search.registered_at_to']
    ) {
      valid['message.bulk.delivery.search.registered_at_from.message'] =
        'アプリ利用開始日(To)が指定されてる時はアプリ利用開始日(From)は必須です'
    }
    if (
      params['message.bulk.delivery.search.last_login_at_from'] &&
      !params['message.bulk.delivery.search.last_login_at_to']
    ) {
      valid['message.bulk.delivery.search.last_login_at_to.message'] =
        '最終ログイン日(From)が指定されてる時は最終ログイン日(To)は必須です'
    }
    if (
      !params['message.bulk.delivery.search.last_login_at_from'] &&
      params['message.bulk.delivery.search.last_login_at_to']
    ) {
      valid['message.bulk.delivery.search.last_login_at_from.message'] =
        '最終ログイン日(To)が指定されてる時は最終ログイン日(From)は必須です'
    }

    if (Object.keys(valid).length > 0) {
      setSearchConditionErrors(valid)
      return true
    }
    setSearchConditionErrors({})
    return false
  }

  const fetchData = async offset => {
    setIsLoading(true)
    await Promise.all([
      fetchContractList({ offset, limit: PAGE_LIMIT }),
      // 複数回コールすることを避けるため
      controlCheckedIdList.size === 0
        ? fetchContractListId({ offset: 0, limit: 0 })
        : null
    ])
    setIsLoading(false)
  }

  const handleChangeSort = sortParam => {
    setOrderBy(sortParam)
    searchCondition['orderBy'] = sortParam
    searchCondition['pageNumber'] = 1
    setPageNumber(1)
  }

  const goToEditBulkDelivery = () => {
    if (checkedIdList.size == 0) {
      enqueueSnackbar('配信先が設定されていません', {
        variant: 'warning'
      })
      return
    }
    if (checkedIdList.size > 10000) {
      return
    }
    setSelectContractList({
      selectContractList: checkedIdList
    })
    history.push('/message/bulk-delivery/edit')
  }

  const fetchContractListId = async ({
    searchCondition,
    newSearchCondition,
    offset,
    limit
  }) => {
    let filter = {}
    let kyk_keiyaku_date = []
    let registeredAtToArr = []
    let lastLoginAtArr = []

    if (newSearchCondition) {
      if (Object.keys(newSearchCondition).length != 0) {
        Object.keys(searchCondition).forEach(function(key) {
          if (searchCondition[key]) {
            switch (key) {
              case 'message.bulk.delivery.search.tenant_id': {
                filter['tenant_id'] = {
                  contains: searchCondition[key]
                }
                break
              }
              case 'message.bulk.delivery.search.kky_id': {
                filter['kky_id'] = {
                  eq: Number(searchCondition[key])
                }
                break
              }
              case 'message.bulk.delivery.search.kky_knr_no': {
                filter['kky_knr_no'] = {
                  eq: searchCondition[key]
                }
                break
              }
              case 'message.bulk.delivery.search.contractor_id': {
                filter['contractor_id'] = {
                  contains: searchCondition[key]
                }
                break
              }
              case 'message.bulk.delivery.search.TTY_NAME': {
                filter['TTY_NAME'] = {
                  contains: searchCondition[key]
                }
                break
              }
              case 'message.bulk.delivery.search.kky_tel_02': {
                filter['kky_tel_02'] = {
                  eq: searchCondition[key]
                }
                break
              }

              case 'message.bulk.delivery.search.kyk_keiyaku_date_from': {
                if (
                  'message.bulk.delivery.search.kyk_keiyaku_date_to' in
                  searchCondition
                ) {
                  kyk_keiyaku_date.push(searchCondition[key])
                } else {
                  enqueueSnackbar('契約日Toを指定してください', {
                    variant: 'error'
                  })
                  return
                }
                break
              }
              case 'message.bulk.delivery.search.kyk_keiyaku_date_to': {
                if (
                  'message.bulk.delivery.search.kyk_keiyaku_date_from' in
                  searchCondition
                ) {
                  kyk_keiyaku_date.push(searchCondition[key])
                } else {
                  enqueueSnackbar('契約日Fromを指定してください', {
                    variant: 'error'
                  })
                  return
                }
                break
              }
              case 'message.bulk.delivery.search.registered_at_from': {
                if (
                  'message.bulk.delivery.search.registered_at_to' in
                  searchCondition
                ) {
                  registeredAtToArr.push(searchCondition[key])
                } else {
                  enqueueSnackbar('アプリ利用開始日Toを指定してください', {
                    variant: 'error'
                  })
                  return
                }
                break
              }
              case 'message.bulk.delivery.search.registered_at_to': {
                if (
                  'message.bulk.delivery.search.registered_at_from' in
                  searchCondition
                ) {
                  registeredAtToArr.push(searchCondition[key])
                } else {
                  enqueueSnackbar('アプリ利用開始日Fromを指定してください', {
                    variant: 'error'
                  })
                  return
                }
                break
              }
              case 'message.bulk.delivery.search.last_login_at_from': {
                if (
                  'message.bulk.delivery.search.last_login_at_to' in
                  searchCondition
                ) {
                  lastLoginAtArr.push(searchCondition[key])
                } else {
                  enqueueSnackbar('最終ログイン日Toを指定してください', {
                    variant: 'error'
                  })
                  return
                }
                break
              }
              case 'message.bulk.delivery.search.last_login_at_to': {
                if (
                  'message.bulk.delivery.search.last_login_at_from' in
                  searchCondition
                ) {
                  lastLoginAtArr.push(searchCondition[key])
                } else {
                  enqueueSnackbar('最終ログイン日Fromを指定してください', {
                    variant: 'error'
                  })
                  return
                }
                break
              }
              case 'message.bulk.delivery.search.is_usage': {
                if (searchCondition[key] == 1) {
                  filter['app_user_id'] = {
                    isNull: 0
                  }
                } else if (searchCondition[key] == 2) {
                  filter['is_use_app'] = {
                    eq: true
                  }
                } else {
                  filter['is_use_app'] = {
                    eq: false
                  }
                }
                break
              }
              default:
            }
          }
        })

        if (kyk_keiyaku_date.length > 0) {
          filter['kyk_keiyaku_date'] = {
            between: kyk_keiyaku_date
          }
        }
        if (registeredAtToArr.length > 0) {
          filter['registered_at'] = {
            between: registeredAtToArr
          }
        }
        if (lastLoginAtArr.length > 0) {
          filter['last_login_at'] = {
            between: lastLoginAtArr
          }
        }
      }
    }

    filter['kyk_status_div'] = {
      eq: [1, 2, 3]
    }

    filter['is_usage'] = {
      eq: true
    }
    filter['is_management'] = {
      eq: true
    }

    let result = await QueryHelper(
      'getContractIdList',
      {
        filter: filter,
        offset: offset,
        limit: limit
      },
      false
    )
    if (result.error) {
      enqueueSnackbar('入居者ID一覧を取得できませんでした', {
        variant: 'error'
      })
    } else {
      let newCheckedIdArray = new Set()
      result.id.forEach(id => newCheckedIdArray.add(id))
      if (!newSearchCondition) {
        setControlCheckedIdList(newCheckedIdArray)
        setAllTransmittableIdList(newCheckedIdArray)
      } else {
        setCheckedIdList(newCheckedIdArray)
        setAllTransmittableIdList(newCheckedIdArray)
      }
    }
  }

  useEffect(() => {
    const offset = pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0
    history.push({
      pathname: `/message/bulk-delivery/search/${pageNumber}`,
      state: searchCondition
    })
    fetchData(offset)
  }, [pageNumber, searchCondition, orderBy, totalNumber, selectContractList])

  function handleChange(event) {
    const clickedId = parseInt(event.target.value, 10)
    let newCheckedIdArray = new Set(checkedIdList)
    if (event.target.checked) {
      newCheckedIdArray.add(clickedId)
    } else {
      newCheckedIdArray.delete(clickedId)
    }
    setCheckedIdList(newCheckedIdArray)
    setSelectContractList({
      selectContractList: newCheckedIdArray
    })
    if (newCheckedIdArray.size > 10000) {
      setErrorMessage('１万件以内で選択してください。')
    } else {
      setErrorMessage('')
    }
  }

  const checkAll = async () => {
    let newSearchCondition = Object.assign({}, searchCondition)
    delete newSearchCondition['pageNumber']
    // 一括チェックボタンを押してかつ変更点がない場合
    if (
      [...checkedIdList].length > 1 &&
      [...allTransmittableIdList].length > 1 &&
      [...checkedIdList].length == [...allTransmittableIdList].length
    ) {
      // チェックを外す
      setCheckedIdList(new Set())
      setSelectContractList({
        selectContractList: new Set()
      })
    } else {
      // 検索条件があった場合
      if (Object.keys(newSearchCondition).length != 0) {
        setIsLoading(true)
        await fetchContractListId({
          searchCondition: searchCondition,
          newSearchCondition: newSearchCondition,
          offset: 0,
          limit: 0
        })
        setIsLoading(false)
        return
      } else {
        setCheckedIdList(controlCheckedIdList)
        setSelectContractList({
          selectContractList: controlCheckedIdList
        })
        setAllTransmittableIdList(controlCheckedIdList)
      }
    }
    if (controlCheckedIdList.size > 10000) {
      setErrorMessage('１万件以内で選択してください。')
    } else {
      setErrorMessage('')
    }
  }

  const handleDeleteSearchCondition = (target, id = null) => () => {
    setCheckedIdList(new Set())
    const params = JSON.parse(JSON.stringify(searchCondition))
    switch (target) {
      case 'message.bulk.delivery.search.kyk_keiyaku_date_from':
      case 'message.bulk.delivery.search.kyk_keiyaku_date_to': {
        delete params['message.bulk.delivery.search.kyk_keiyaku_date_from']
        delete params['message.bulk.delivery.search.kyk_keiyaku_date_to']
        break
      }
      case 'message.bulk.delivery.search.registered_at_from':
      case 'message.bulk.delivery.search.registered_at_to': {
        delete params['message.bulk.delivery.search.registered_at_from']
        delete params['message.bulk.delivery.search.registered_at_to']
        break
      }
      case 'message.bulk.delivery.search.last_login_at_from':
      case 'message.bulk.delivery.search.last_login_at_to': {
        delete params['message.bulk.delivery.search.last_login_at_from']
        delete params['message.bulk.delivery.search.last_login_at_to']
        break
      }
      default: {
        delete params[target]
        break
      }
    }
    params['pageNumber'] = 1
    setSearchCondition(params, setPageNumber(1), setOrderBy({}))
  }

  function isContainsMessageBySearchConditions() {
    const val = Object.keys(searchCondition).find(x => x.match(/^message.+/))
    if (val === undefined) {
      return Object.keys(searchCondition).indexOf('selectedStatus') !== -1
    }
    return true
  }

  function SearchConditionChips() {
    return (
      <Grid item xs={12}>
        {searchCondition &&
          Object.keys(searchCondition).length > 0 &&
          isContainsMessageBySearchConditions() && (
            <Paper component="ul" className={classes.chips}>
              {Object.keys(searchCondition).map(key => {
                let label = ''
                switch (key) {
                  case 'message.bulk.delivery.search.tenant_id': {
                    label = '入居者名: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.kky_knr_no': {
                    label = '顧客番号: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.contractor_id': {
                    label = '契約者名: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.kky_tel_02': {
                    label = '電話番号: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.TTY_NAME': {
                    label = '物件名: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.kyk_keiyaku_date_from': {
                    label = '契約日From: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.kyk_keiyaku_date_to': {
                    label = '契約日To: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.registered_at_from': {
                    label = 'アプリ利用開始日From: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.registered_at_to': {
                    label = 'アプリ利用開始日To: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.last_login_at_from': {
                    label = '最終ログイン日To: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.last_login_at_to': {
                    label = '最終ログイン日From: ' + searchCondition[key]
                    break
                  }
                  case 'message.bulk.delivery.search.is_usage': {
                    switch (searchCondition[key]) {
                      case AppUsage.none.code + '':
                        label = 'アプリ利用: ' + AppUsage.none.value
                        break
                      case AppUsage.enabled.code + '':
                        label = 'アプリ利用: ' + AppUsage.enabled.value
                        break
                      case AppUsage.disabled.code + '':
                        label = 'アプリ利用: ' + AppUsage.disabled.value
                        break
                    }
                    break
                  }
                  case 'message.bulk.delivery.search.kyk_status_div': {
                    label = '退去者を含む: ON'
                    break
                  }
                  default:
                }

                return (
                  <>
                    {label !== '' && (
                      <CommonChip
                        label={label}
                        onDelete={handleDeleteSearchCondition(key)}
                      />
                    )}
                  </>
                )
              })}
            </Paper>
          )}
      </Grid>
    )
  }

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%'
      }}
    >
      <Grid align="right">
        <Button
          variant="contained"
          className={classes.csvButton}
          onClick={csvDownLoad}
          disabled={checkedIdList.size == 0}
        >
          CSV出力
        </Button>
      </Grid>
      <Grid container spacing={3}>
        <SearchConditionChips />
        <Grid item xs className={classes.tableTips}></Grid>
        <Grid item xs={6} className={classes.tableTips}></Grid>
        <Grid item xs className={classes.tableTips}></Grid>
      </Grid>
      {!isLoading ? (
        <Paper className={classes.root}>
          <div className={classes.tableWrapper}>
            <List
              contractList={contractList.data}
              handleChange={handleChange}
              checkedIdList={checkedIdList}
              orderBy={orderBy}
              handleChangeSort={handleChangeSort}
              offset={pageNumber > 1 ? (pageNumber - 1) * PAGE_LIMIT : 0}
              transmittableIdList={transmittableIdList}
            />
          </div>
        </Paper>
      ) : (
        <Loading isLoading={isLoading} marginTop={20} />
      )}
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <Button
            variant="contained"
            className={classes.button}
            onClick={checkAll}
          >
            一括チェック
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Typography className={classes.message}>
            {'※選択できるのは１万件までです。'}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Pagination
            handlePageNumber={handleCustomChangePage}
            pageNumber={pageNumber}
            count={contractList.paging.count}
            rowsPerPage={PAGE_LIMIT}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <FormHelperText error>{errorMessage}</FormHelperText>
        </Grid>
        <Grid item xs={1} align="right">
          <Button
            component={Link}
            onClick={() => goToEditBulkDelivery()}
            variant="contained"
            className={classes.nextButton}
          >
            次へ
          </Button>
        </Grid>
      </Grid>
      <SearchFormModalDialog
        content={
          <SearchFormContent
            searchCondition={searchCondition}
            searchConditionErrors={searchConditionErrors}
          />
        }
        footer={
          <SearchModalDialogFooter
            setSearchConditionErrors={setSearchConditionErrors}
          />
        }
        postData={searchPostData}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    parameters: state.handle.parameters,
    selectContractList: state.selectContractList.list
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleClear: () => {
      dispatch(handleClear())
    },
    closeSearchModalDialog: () => {
      dispatch(closeSearchModalDialog())
    },
    setSelectContractList: list => {
      dispatch(setSelectContractList(list))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ListContent))
